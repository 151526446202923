.nav-bar {
    display: none;

    @media (min-width: 768px) {
        display: block;
        margin-bottom: 1rem;

        &-list {
            border: 2px solid #161616;
            display: flex;
            list-style: none;
            padding: 0;
            width: 100%;

            &-item {
                flex: 1;

                &-button {
                    background: #fff;
                    border: 0;
                    color: #161616;
                    font-family: KairosSans, sans-serif;
                    font-size: 0.875rem;
                    font-weight: 700;
                    line-height: 1.125rem;
                    padding: 0.437rem 1rem 0.437rem 0;
                    text-align: start;
                    text-transform: uppercase;
                    width: 100%;

                    &-span {
                        border-left: 1px solid #D9D9D9;
                        padding-left: 1rem;
                    }

                    &:hover {
                        background: #D9D9D9;
                    }
                }

                .selected {
                    background: #161616;
                    border-left: 0;
                    color: #fff;
                }
            }
        }
    }
    @media (min-width: 920px) {
        &-list {
            &-item {
                width: 11.31rem;
            }
        }
    }
    @media (min-width: 1100px) {
        &-list {
            &-item {
                width: 13.31rem;
            }
        }
    }

}

.dropdown-navigation {
    display: flex;
    flex-direction: column;
    font-family: KairosSans, sans-serif;
    position: relative;
    text-transform: uppercase;

    .click-to-expand-span {
        color: #555555;
        font-family: DINPro, sans-serif;
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 0.375rem 0 1.25rem;
        text-transform: none;
    }

    .navigation-menu-dropdown {
        align-items: center;
        background: #000;
        border-radius: 4px;
        color: #fff;
        display: flex;
        font-family: KairosSans, sans-serif;
        justify-content: space-between;
        text-transform: uppercase;
        width: 100%;

        .navigation-selected-dropdown-title {
            color: #fff;
            font-size: 1rem;
            margin-bottom: 0;
            padding: 10px;
        }

        svg {
            height: 0.614rem;
            margin-bottom: 3px;
            margin-right: 1rem;
            width: 1.05rem;
        }
    }

    .inverted {
        svg {
            transform: rotate(180deg);
        }
    }

    .dropdown-sub-menu {
        filter: drop-shadow(0 4px 12px rgba(16, 24, 40, 0.3));
        margin-top: 0.25rem;
        position: absolute;
        top: 45%;
        width: 100%;
        z-index: 100;

        .dropdown-nav-item {
            .nav-item-button {
                background: #fff;
                border: none;
                font-size: 1rem;
                line-height: 1.5rem;
                padding: 10px;
                text-align: start;
                text-transform: uppercase;
                width: 100%;
            }
        }
    }

    @media (min-width: 768px) {
        display: none;
    }
}
