/**
* Datatables CSS
*/

table.datatable td {
    border-width:0px;
}

table.datatable tr:nth-child(odd) {
    background: #F8F8F8;
}
table.datatable tr:nth-child(even) {
    background: #FFF;
}

table.datatable .red, table.el-table__body .red {
    color:red;
}

table.datatable .mdi, table.el-table__body .mdi {
    margin-left:5px;
    margin-right:5px;
    font-size: 1.5rem;
}

table>thead{
    background-color:#000 !important;
    font-family: KairosSans;
    text-transform: uppercase;
    color:#FFF;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: left;
}


table.datatable thead .sorting::before {
    color:#FFF;
    opacity: 0.2;
    right: auto;
    display:inline;
}
table.datatable thead .sorting::after {
    color:#FFF;
    opacity: 0.2;
    right: auto;
    display:inline;
}
table.datatable thead .sorting_asc::before {
    color:#FFF;
    opacity:1;
    right: auto;
    display:inline;
    position: initial;
}
table.datatable thead .sorting_asc::after {
    color:#FFF;
    opacity:0;
    right: auto;
    display:inline;
    position: initial;
}
table.datatable thead .sorting_desc::before {
    color:#FFF;
    opacity:0;
    right: auto;
    display:inline;
    position: initial;
}
table.datatable thead .sorting_desc::after {
    color:#FFF;
    opacity:1;
    right: auto;
    display:inline;
    position: initial;
}

table.datatable .title-case {
    text-transform: capitalize;
}

.datatable-photo img{
    max-width:70px;
    max-height:70px;
    min-width:70px;
    min-height:70px;
    width:auto;
    height:auto;
    object-fit: cover;
}

table#users-table {
    width:100% !important;
}

.dataTables_info, .dataTables_length {
    display: inline-block;
    // margin-right: 2rem;
}

.dataTables_paginate, a#new-user {
    float:right;
}

.loading-image {
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
    margin:1em;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

div.hidden {
    margin-top: 1em;
    display:none;
}

#advanced_search_wrapper {
    margin-bottom: 1em;
    flex: 1;
}


#advanced_search_criteria>span:first-of-type{
    margin-right: 2.5em;
}

.add-row {
    cursor: pointer;
    margin-right:8em;
    border: 1px solid #999;
    width: 150px;
    padding: 2px;
    margin-bottom: 10px;
    border-radius: 3px;
}
.add-row:hover {
    background-color: #43752f;
    border-color: #43752f;
}

.page-link {
    border-radius: 0px;
}
div.pagination-bar {
    margin-top: 15px;
    font-family: 'KairosSans', serif;
    div.el-pagination {
        button.btn-next, button.btn-prev {
            color: white !important;
            background-color: black !important;
            border-color: black !important;
        }
        ul.el-pager {
            li.number {
                padding: 1px !important;
                color: white !important;
                background-color: black !important;
                border-color: black !important;
                &.active {
                    color: black !important;
                    background-color: #e7f816 !important;
                    border: #e7f816 !important;
                }
            }
        }
    }
    span.el-pagination__sizes, span.el-pagination__jump {
        input {
            border-color: #000 !important;
        }
    }
}
div.el-select-dropdown__wrap.el-scrollbar__wrap.el-scrollbar__wrap--hidden-default {
    font-family: 'KairosSans', serif;
    .el-select-dropdown__item {
        color: #000 !important;
    }
}

.actions-col.single-entry-fix div.dropdown-menu.show {
    position: fixed !important;
    transform: unset !important;
    top: auto !important;
    left: auto !important;
}

.el-table, .el-table__body-wrapper {
    overflow-x: visible !important;
    overflow-y: scroll;
}
