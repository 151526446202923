
/**
* Roster/Recruit CSS
*
* CSS fixes specifically for the
* roster and recruit search pages
*/

.player-progress {
    display: flex;
    justify-content: center;
    .inner-progress {
        display: flex;
        justify-content: center;
    }
    .full-progress {

        border-radius: 60px;
        margin: 0px 36px;
        .round {
            border: none !important;
        }
    }
    .round {
        border-radius: 50%;
        border: 1px solid #666;
        background: #E7E7E7;
        height: 28px;
        width: 28px;
        position: relative;
        i {
            left: 50% !important;
            top: 50%;
            font-size: 14px;
            position: absolute;
            transform: translate(-50%, -50%);
        }
    }
    .org-bg {
      background: #E7F816;
    }
    .org-text {
      color: #000;
    }
}

.org-bg {
    background: #E7F816;
}

.player-progress.full-progress {
    border-radius: 60px;
    margin: 0px 36px;
}

#roster-table_wrapper>.row{
    display:initial;
}

tr{
    border-bottom: 1px solid #666;
    height:3rem;
}

h4.player-name, h2.roster-header{
    margin-bottom:0px;
}

h2.roster-header{
    display:inline-block;
}

.select2-container, #roster-filter .form-control{
    color: #000;
    font-family: KairosSans;
    height: 46px;
    margin-left: 5px;
    text-transform: uppercase;
}
.select2-container {
    margin-top: 0px !important;
}

#roster-filter .select2-container, .form-inline .select2-container{
    width:initial !important;
    padding-left: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-left: -2px;
    margin-top: -4px;
}


span#view-player, span.edit, span#remove-player{
    cursor:pointer;
}

h3.position-rank{
    display:inline-block;
}

span.reorder-button{
    margin-left:-3em;
    padding-left:1em;
    margin-right:1em;
    cursor:grab;
}

#stats-row {
    margin-top: 1em;
}

div#prospects-dt {
    margin-bottom: 150px;
}


/** Profile picture column **/
table#roster-table tr>th:nth-child(2),
table#roster-table tr>td:nth-child(2){
    padding:0px;
}

th#roster-player:before {
    margin-left: -1em;
}
th#roster-player:after {
    margin-left: 0em;
}

.addBtn, .addToProspects, .addToRoster, .removeBtn, .removeFromProspects, .removeFromRoster {
    cursor: pointer;
    height: 20px;
    width: 20px;
    margin-left: 1.5px;
    line-height: 18px;
    font-size: 20px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
}

.addBtn, .addToProspects, .addToRoster {
    color: #000;
    background-color: $primary;
}

.removeBtn, .removeFromProspects, .removeFromRoster {
    color: #999;
    background-color: $black;
}

.modal-body .form-group .select2-selection--multiple {
    max-height: 46px;
    min-height: 44px;
    border-radius: 0px;
}

.modal-body .form-group .select2-selection--multiple .select2-selection__rendered {
    max-height: 44px;
    overflow-y: auto;
    min-height: 44px;
}

.select2-container--open .select2-dropdown--below {
    margin-top: 16px;
}

.modal-body .add-player-modal-btn {
    float: right;
}

.dt-large-col {
    min-width: 150px;
}

#advanced_search_criteria {
    #dateInput {
        margin-top: 2.5px;
    }
}

@media(max-width: 991px) {
    #advanced_search_criteria #videoFilter{
        margin-bottom: 15px;
    }
}

@media(max-width: 900px) {
    #stats-row .card-box-custom-container {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media(max-width: 944px) {
    #rosterSearchInfo #roster-filter {
        width: 100%;
    }
    #rosterSearchInfo .add-player-icon {
        float: right;
    }
}

@media(max-width: 944px) and (min-width: 801px) {
    #rosterSearchInfo #roster-search {
        width: 50%;
        margin-left: 0px;
    }
    #rosterSearchInfo .select2 {
        width: calc(25% - 4px ) !important;
        padding-left: 0px;
    }

}

@media(max-width: 845px) {
    #rosterFilter #roster-filter .select2 {
        width: 50% !important;
        margin-bottom: 4px !important;
        float: left;
    }
    #rosterFilter #roster-filter #recruit-search {
        width: calc(100% - 5px);
        margin-left: 5px;
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }
    #rosterFilter #searchBtn {
        margin-left: 5px !important;
    }
    #rosterFilter #advanced_searchBtn {
        float: right;
    }
    #rosterFilter #roster-filter {
        width: 100%;
    }
}

@media(max-width: 800px) {
    #rosterSearchInfo #roster-filter {
        float: left !important;
        width: 100%;
    }
    #rosterSearchInfo #roster-search {
        width: 100%;
        margin-top: 4px !important;
        margin-bottom: 4px !important;
        margin-left: 0px !important;
    }
    #rosterSearchInfo .select2 {
        width: calc(50% - 1.7px ) !important;
        padding-left: 0px;
    }
}

@media(max-width: 767px) {
    .modal-body .form-group.grad-year {
        margin-top: 15px;
    }
}

@media(max-width: 600px) {
    #roster-table_wrapper .dataTables_length{
        width: 100%;
        text-align: center;
    }
    #roster-table_wrapper .dataTables_length {
        margin-top: 4px;
    }
    #roster-table_wrapper .paging_simple_numbers {
        width: 100%;
    }
    #roster-table_wrapper .pagination {
        justify-content: center;
        margin-bottom: 20px;

    }
}

@media(max-width: 576px) {
    #dynamic-component.modal .modal-dialog .close {
        top: 9px;
        right: 9px;
        height: 30px;
        width: 30px;
    }
}

@media(max-width: 575px) {
    #roster-search {
        margin-top: 3px !important;
    }
}

@media(max-width: 498px) {
    #advanced_search_criteria {
        margin-top: 0px !important;
    }
    #rosterFilter #page-title {
        width: 100%;
    }
    #rosterFilter #saved-searches {
        float: left !important;
    }
    #rosterFilter #simple_search {
        padding-left: 0px !important;
    }
}

@media(max-width: 490px) {
    #rosterSearchInfo ul.add-player-icon {
        width: 100%;
        padding: 0px;
        text-align: center;
    }
}
@media(max-width: 450px) {
    #dynamic-component .select2 {
        width: 100% !important;
    }
    .select2-container.select2-container--open,
    .select2-dropdown {
        width: calc(100% - 29px) !important;
    }
}

