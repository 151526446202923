// table#announcements-table{
//     width:100% !important;
// }
// .announcement-comments {
//     .comment-border {
//         margin-top: 12px;
//         margin-left: 10px;
//         margin-right: 10px;
//     }
// }
// .announcement-comments .comment-container {
// 	overflow: hidden;
//     overflow-y: scroll;
//     margin-bottom: 3px;
// }
// @media(min-width: 1199px) {
//     .announcement-comments .comment-container {
//         max-height: calc(100vh - 530px);
//     }
//     .announcement-comments .comment-container::-webkit-scrollbar {
//         left: -15px;
//         width: 6px;
//     }
//     .announcement-comments .comment-container::-webkit-scrollbar-thumb {
//         border-radius: 20px;
//         background-color: rgba(0,0,0,1);
//         width: 6px;
//     }
//     .announcement-comments .comment-container::-webkit-scrollbar-track {
//         width: 6px;
//         border-radius: 20px;
//         background-color: #ccc;
//         -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//         border-right: solid 10px transparent;
//     }
// }
// .comment-box {
// 	height: 110px !important;
// }

// #comments {
//     margin-bottom: 20px;

//     textarea {
//         margin-bottom:10px
//     }

//     textarea:focus {
//         background-color: #FFF;
//     }

//     button {
//         float: right;
//     }

//     .new-note, .new-comment {
//         margin-bottom: 60px;
//     }

//     .comment {
//         margin: 35px 0 35px 50px;
//         position: relative;

//         div.actions * {
//             padding-right: 15px;
//         }

//         strong {
//             color: #000;
//             font-weight: 500;
//         }

//         pre {
//             font-size: 14px;
//         }

//         .body {
//             padding: 5px 0 10px;
//             color: #333;
//         }

//         .btn-group {
//             button {
//                 padding:5px;
//             }
//         }

//         &::before {
//             content: "";
//             background-color: #eee;
//             top: 40px;
//             bottom: 0;
//             left: -32px;
//             height: 100%;
//             width: 2px;
//             position: absolute;
//         }

//         &:last-child {
//             margin-bottom: 0px;

//             &::before {
//                 background-color: white;
//                 height: 0;
//             }
//         }
//     }


    .comment-photo {
        object-fit: cover;
        height: 3rem;
        width: 3rem;
        margin-left: -55px;
        position: absolute;
        border-radius: 50%;
        z-index: 1;
    }

//     .actions a {
//         color: #999;
//     }

//     #note-filter {
//         margin-bottom: 20px;
//     }

//     #note-filter {
//         .btn-group {
//             button {
//                 padding: 15px 25px;
//                 border: none !important;
//                 text-transform: uppercase;
//                 font-size: 14px;
//                 cursor: pointer;
//             }
//         }
//     }
// }

// @media(max-width: 768px) {
// 	.mt-sm-10 {
// 		margin-top: 10px;
// 	}
// }
// 

i.rotate {
    transform: rotate(-90deg);
}
.post-box__card {
    padding-bottom:23px !important;
}
.post-box__actions {
    display:flex;
    top:5px;
}
.post-box__user-img {
    width:40px;
    height:40px;
    margin-right:15px;
}
.post-box__media-actions {
    margin-right: 5px;
    padding: 20px;
    position: relative;
    height: 35px;
    align-self: flex-end;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        font-size: 18px;
        width: 100%;
        height: 100%;
        background-color: #00586f;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.post-box__textarea {
    margin-right:38px;
    border:none;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    position:relative;
    width:100%;
    top:5px;
    height: 30px;
}
.post-box__actions-bar {
    right: 55px;
    margin-bottom: 0;
    transform: none;
    position: absolute;
}
.post-box__submit {
    padding: 20px;
    position:relative;
    right: 0px;
    height: 35px;
    align-self: flex-end;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
    }
}

/* Preview section of the post box */
.post-box-preview {
    margin-top: 25px;
    display:flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);

}
.post-box-preview__item {
    height:100px;
    width:100px;
    position:relative;
    margin-right:5px;
    margin-bottom:5px;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
}
.post-box-preview__add {
    border:1px dashed rgba(120, 130, 140, 0.13);
    position:relative;
}
.post-box-preview__add-more {
    cursor: pointer;
    font-size:40px;
    position:absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.post-box-preview__remove {
    color: #000;
    font-size:30px;
    background-color: transparent;
    position: relative;
    height:20px;
    width:20px;
    border-radius: 100px;
    float: right;
    top: -7px;
    right: -4px;
    cursor:pointer;
}
.post-box-preview__remove i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-100%, -37%);
    width: 9.5px;
    height: 36.5px;
    font-size: 25px;
}
.feed-main {
    padding-left: 60px;
}
.feed-item {
    min-height: 70px;
    position: relative;
}
.feed-item::before {
    content: "";
    background-color: #eee;
    top: 41px;
    bottom: 0;
    left: -32px;
    height: calc(100%);
    width: 2px;
    position: absolute;
    z-index: 0;
}
.feed-item .content {
    margin-top: 10px;
    margin-bottom: 10px;
}
.feed-item .fitVids {
    margin-top: 20px;
}
.show-more {
    cursor: pointer;
}
.post-btn {
    width: 52px;
    height: 45px;
}
.upload-btn {
    position: absolute;
    top: 0;
    right: 55px;
    padding: 0px 14px;
}
.submit-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px 14px;
}
.submit-btn i {
    font-size: 30px;
} 
.upload-btn i {
    font-size: 27px;
    margin-left: -3px;
}
.post-box {
    width: calc(100% - 102px);
}
.post-border {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba(0,0,0,.1);
    height: 1px;
}
textarea.post-edit {
    height: 49px;
    margin-bottom: 10px;
    border: 1px solid rgba(120, 130, 140, 0.13) !important;
}
textarea.comment-box {
    height: 44px;
    margin-bottom: 10px;
    border: 1px solid rgba(120, 130, 140, 0.13) !important;
    line-height: 37px;
    padding-left: 20px;
    width: calc(100% - 56px);
}
.modify-comment textarea.comment-box {
    width: calc(100% + 49px);
    margin-left: -49px;
    margin-top: 6px;
}
.submit-btn.comment-submit {
    top: 4px;
    right: 9px;
}
.post-comments .comment-container {
    min-height: 65px;
    padding: 10px 0px 10px 50px !important;
}
.btn.edit-comment {
    padding: 2px 7px;
    font-size: 14px;
}
.media-upload-btn {
    padding: 0px 14px;
    width: 52px;
    height: 45px;
}
.media-upload-btn i {
    font-size: 25px;
}
.media-upload-btn[add-image] {
    margin-right: -2px;
}
p.comment-content {
    margin-bottom: 10px;
}
img.post-image {
    max-width: 775px;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 13px;
}
.multi-images {
    text-align: center;
    margin-bottom: 13px;
}
.multi-images .img-responsive{
    background-size: cover !important;
    background-position: center !important;
    vertical-align: top;
    width: 100%;
    height: auto;
    display: inline-block;
    padding-top: 70%;
}
.multi-images .img-responsive-wrap:nth-child(odd) {
    width: calc(50% - 8px);
    margin: 8px 8px 8px 0;
    float: left;
}
.multi-images .img-responsive-wrap:nth-child(even) {
    width: calc(50% - 8px);
    margin: 8px 0 8px 8px;
    float: left;
}
.multi-images .img-responsive-wrap:nth-child(odd):last-child {
    width: 100% !important;
    margin: 8px 0px 8px 0 !important;
    float: left;
}
#uploadPreview video, .feed-item video {
    width: 100%;
}
.post-box-preview .videos-container {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: -5px;
}
.post-box-preview .videos-container .post-box-preview__remove {
    top: 10px !important;
}
.feed-main .no-results {
    margin-top: 29px;
}