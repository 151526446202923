#vjs_video_3{
    height: 100%;
    width: 100%;
}

.script-diagram {
    position: relative;
    width: 22vw;
}

@media(max-width: 767px) {
    .script-diagram {
        width: 55vw !important;
    }
}