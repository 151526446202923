
.card-box-yellow {
    background-color: $primary !important;
    color: $black;
    h1, h2, h3, h4, h5, p {
        color: $black;
    }
}

.card-box-black {
	background-color: black !important;
	color: $primary;
    h1, h2, h3, h4, h5 {
	   color: $primary;
    }
    p {
	   color: #ccc;
    }
}

.card-box-gray {
	background-color: #ccc !important;
	color: $black;
    h1, h2, h3, h4, h5, p {
	   color: $black;
    }
}

.card-box-yellow p, .card-box-gray p, .card-box-black p {
	font-size: 10px;
	margin-bottom: 0px;
}

.profile-pic-sm {
	width: 100%;
	height: auto;
	min-width: 60px;
}

.player-btn {
	padding: 2px 12px;
	margin-bottom:7px;
	font-size: 12px;
}

.black-dropdown {
	padding-top: 2px;
	padding-bottom: 2px;
	color: $primary;
}
.black-dropdown.show > .btn-secondary.dropdown-toggle, .black-dropdown.show > .btn-secondary.dropdown-toggle:focus {
	color: $primary !important;
	border-color: $primary !important;
	box-shadow: 0 0 0 1px $primary !important;
}
.black-dropdown-btn:hover {
	color: $primary !important;
	border-color: $primary !important;
	box-shadow: 0 0 0 1px $primary !important;
}
.black-dropdown .dropdown-menu {
	background-color: black !important;

}
.black-dropdown-btn {
	background-color: black !important;
	color: $primary;
}
.black-dropdown-btn:focus {
	box-shadow: none !important;
}
.black-dropdown h5, .black-dropdown a {
	background-color: black;
	color: $primary;
	margin-bottom: 0px !important;
    float: left;
    margin-top: 7px;
    margin-right: 4px;
}
.black-dropdown a:hover, .black-dropdown a.active, .black-dropdown a:hover h5 {
	background-color: black !important;
	color: #CCC;
}
.black-dropdown > .btn-secondary.dropdown-toggle::after {
    transform: rotate(90deg);
}

.circle-checkbox-container input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}
.circle-checkbox {
	display: block;
	cursor: pointer;
	position: absolute;
	top: 19;
	left: 36;
	height: 20px;
	width: 20px;
	border: 1px solid #ccc;
	border-radius: 100%;
}
.circle-checkbox .check {
	display: none;
	color: $primary;
	position: absolute;
	top: 3px;
	left: 4px;
	font-size: 12px;
}
.circle-checkbox-container input:checked ~ .circle-checkbox, .circle-checkbox-container .circle-checkbox:hover, .circle-checkbox-container .circle-checkbox.active {
	background-color: black;
	color: $primary;
	border: 1px solid black;

}
.circle-checkbox-container input:checked ~ .circle-checkbox .check, .circle-checkbox-container .circle-checkbox:hover .check, .circle-checkbox-container .circle-checkbox.active .check {
	display: block !important;
}

.gray-btn {
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #ccc;
	padding: 1px 12px 3px 12px;
	vertical-align: middle;
}
.gray-btn:hover {
	background-color: #ccc;
	color: white;
}

.gray-text {
	color: #ccc;
}

.fw-light {
	font-weight: lighter !important;
}
.f-size-12 {
	font-size: 12px;
}
.f-size-13 {
	font-size:13px;
}

.circular-chart {
  display: block;
  width:70px;
  position:absolute;
  top: 0;
  left: 6%;
}

.circle {
  stroke: #9e9e9e;
  fill: none;
  stroke-width: 4.0;
  stroke-linecap: square;
  animation: progress 1s ease-out forwards;
  width: 70px;
}
.circle-yellow {
	stroke: $primary;
}
.circle-black {
	stroke: black;
}
.circle-percentage {
	position: absolute;
	top: 26px;
    left: 6%;
	// margin-left: 20px;
    width: 70px;
    text-align: center;
}


.todo-input {
	border-radius: 5px;
	border: 1px solid #ccc;
}

.masonry {
	min-height: 300px;
}

// Keyframes
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}


// Media Queries
@media(min-width:1200px){
    .container-max-width{
        max-width: 1300px !important;
    }
}

@media (max-width:1314px) and (min-width:1200px) {
    .player-btn {
        padding: 2px 4px !important;
        margin-right: 2px !important;
    }
}
@media (max-width:802px) and (min-width:768px) {
    .player-btn {
        padding: 2px 4px !important;
        margin-right: 2px !important;
    }
}
@media (max-width: 392px) {
    .player-btn {
        padding: 2px 4px !important;
        margin-right: 2px !important;
    }
}
@media (max-width: 352px) {
    .player-btn {
        width: 115px !important;
    }
}

@media (max-width: 320px) {
    .profile-pic-container {
        padding-left: 2px;
    }
}

@media(max-width: 1109px) and (min-width: 768px) {
    .card-box-gray {
        height: 110px !important;
    }
    .card-box-yellow {
        height: 110px !important;
    }
    .card-box-black {
        height: 110px !important;
    }
}

@media(max-width: 991px) and (min-width: 768px) {
    .dash-container {
        max-width: 850px !important;
    }
}

.clickable {
    cursor: pointer;
}
