#announcement {
    .announcement-filters {
        display: inline-block;
        .select2-container {
            width: auto !important;
            min-width: 200px;
        }
    }
    .announcement-checkbox {
        margin-right: 10px;
        margin-left: 10px;
    }
    .checkbox-filters {
        .parsley-errors-list {
            position: absolute;
            top: 31px;
            right: 173px;
            padding-right: 65px;
        }
    }
    .note-editable {
        min-height: 150px;
    }
    .mr-5 {
        margin-right: 5px !important;
    }
    .mt-20 {
        margin-top: 20px;
    }
    #draft-btn,
    #save-btn {
        color: #000;
        font-family: KairosSans;
        text-transform: uppercase;
        padding: 10px;
        margin-left: 7px;
    }
    #announcements-table_filter {
        display: none !important;
    }
}
