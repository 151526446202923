.player-stat-tile {
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 11.25rem;
    justify-content: center;
    position: relative;
    width: 11.125rem;

    &__has-video {
        background-color: #4f4f4f;

        .player-stat-tile-text-container {
            .stat-value {
                color: #fff;
            }
            .stat-name {
                color: #fff;
            }
        }
    }


    &-thumbnail {
        border-radius: 4px;
        filter: grayscale(1) brightness(0.6);
        height: 100%;
        left: 0;
        object-fit: cover;
        object-position: 0 10%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-text-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .video-player-button, svg {
            height: 2.5rem;
            width: 2.5rem;

            @media (min-width: 768px) {
                height: 2.913rem;
                width: 2.913rem;
            }
        }

        .stat-value {
            color: #000;
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0.6875rem 0 0;
            padding: 0 1.5rem;
            text-align: center;
        }

        .stat-name {
            color: #000;
            font-family: DINPro, sans-serif;
            font-size: 0.875rem;
            line-height: 1.25rem;
            text-transform: capitalize;
        }

        .light {
            color: #F8F8F8;
        }
    }

    @media (min-width: 768px) {
        height: 11.1675rem;
        width: 17.525rem;

        &-thumbnail {
            border-radius: 0;
        }
    }

    @media (max-width: 375px) {
        width: 9.125rem;
    }
}
