p.timestamp {
    margin-bottom:5px;
    font-size:.8em;
    color: #888888;
}

p.timestamp a:hover{
    font-weight: bold;
    cursor: pointer;
}
.mark-all-blade-btn {
    border: none;
    float: right;
}
.mark-all-dropdown-btn {
    background-color: #eeeeee;
    border: none;
    border-radius: 0 0 0.25rem 0 !important;
    cursor: pointer;
    text-align: right;
}
#dropdown-menu {
    min-width: max-content !important;
}
.view-all-dropdown-button{
    border-radius: 0 0 0 0.25rem !important;
}