.auth {
    height: 100%;
    background-color: #f8f8f8;

    body {
        padding: 0;
        margin: 0;
        height: 100%;

        @media(max-width: 1160px) {
            height: auto;
        }
    }

    #splash {
        background-color: #000;
        padding: 15px;
        text-align: left;
        position: relative;

        .logo {
            max-width: 30px;
            width: 100%;
        }
        .logo-full {
            display: none;
        }
        h3 {
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -40%);
        }

        @media(min-width: 1160px) {
            height: 100%;
            width: 40%;
            float:left;
            position: relative;
            display: block;

            .logo {
                display: none;
            }
            .logo-full {
                max-width: 400px;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            h3 {
                display: none;
            }
        }
    }

    #auth {
        height: 100%;
        background-color: #f8f8f8;

        h1 {
            margin-bottom: 30px;
            font-size: 40px;
            text-align: center;
        }

        .auth-form {
            padding: 30px;
            max-width: 550px;
            margin: 0 auto;
        }

        @media(min-width: 1160px) {
            height: 100%;
            width: 60%;
            float:left;
            position: relative;

            h1 {
                text-align: left;
                font-size: 45px;
            }

            .auth-form {
                position: absolute;
                width: 100%;
                top: 50%;
                left:50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
