.numbers-wrapper {
    padding-top: 150px;
    .tv-number {
        padding-bottom: 140px;
    }
}

.slick-prev {
    width: 50px;
    height: 44px;
    left: -2px;
    z-index: 1;
    background: #000 !important;
    color: #000 !important;
}

.slick-next {
    width: 50px;
    height: 44px;
    right: -2px;
    z-index: 1;
    background: #000 !important;
    color: #000 !important;
}

table#tv-table {
    tr#tv-head {
        overflow: hidden;
        min-width: 100%;
        width: 0;
        text-align: center;
    }
}

div#tv-body-row {
    .card-box {
        cursor: pointer;
        height: 160px !important;
        center > h6 {
            margin-top: 10px !important;
        }
        div.text-center {
            margin-top: 25px !important;
        }
    }
}
