#playersModal {
    background-color: #fff;
    border: 1px solid;
    display: none;
    height: 500px;
    left: 50%;
    overflow: scroll;
    position: fixed;
    top: 50%;
    text-align: center;
    transform: translate(-50%,-50%);
    width: 700px;
    z-index: 3;

    .players-content {
        padding-bottom: 100px;
        position: relative;

        .button-wrapper {
            bottom: 40px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }
}

a#new-organization {
    float:right;
    margin-bottom: .5rem;
}
#CoachTable {
    .badge {
        background-color: $green;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 5px;
    }
}
#CoachTable_wrapper {
    #CoachTable_paginate {
        float: right !important;
    }
}

table#organizations-table, table#users-table {
    width:100% !important;
    .img-container {
        position: relative;
    }
    .badge {
        background-color: $green;
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        padding: 0px;
        left: 4px;
        bottom: -21px;
    }
}
.organization-pic{
    max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto;
}

.profile-card__data {
    p {
        font-size: 14px;
    }
}

.profile__social {
    padding-left: 0;
    li {
        display: inline;
        a {
            background-color: $black;
            border-radius: 100px;
            i {
                color: $white;
                &:hover {
                    color: $primary;
                }
            }
        }
        .instagram {
            padding: 7px 11px;
        }
        .twitter {
            padding: 7px 10px;
        }
        .tiktok {
            padding: 7px 10px;
        }
        .facebook {
            padding: 7px 11px;
        }
        .video-camera {
            padding: 7px 10px;
        }

    }
}

.video-title-upload {
    display: inline;
    width: 100%;
    p {
        font-size: 12px;
        margin-bottom: 5px;
        .fa-plus {
            background-color: $primary;
            font-size: 8px;
            border-radius: 100px;
            vertical-align: middle;
            padding: 3px 4px;
            transform: translateY(-2px);
        }
        .fa-minus {
            background-color: $red;
            font-size: 8px;
            border-radius: 100px;
            vertical-align: middle;
            padding: 3px 4px;
            transform: translateY(-2px);
            &:before {
                color: $white;
            }
        }

    }
}


.update-file {
    margin-right: 10px;
}

.position-styles {
    padding-bottom: 15px;
    .col-sm-6 {
        ul {
            padding-left: 0;
            li {
                list-style-type: none;
                padding-left: 5px;
                padding-bottom: 5px;
            }
        }
    }
}

.position-styles__title {
    text-align: center;
    h5 {
        padding-top: 5px;
        display: inline-block;
    }
    ul {
        display: inline-block;
    }
}
.profile-card__title {
    text-align: right;
}

.profile-card__info {
    text-align: left;
    h5 {
        padding-top: 4px;
    }
}


.upload-video__wrap {
    border-style: dashed;
    border: dashed 1px #ccc;
    height: 250px;
    position: relative;
    .upload-video__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
    }
}

.deleted-file__wrap {
    width: 100%;
    height: 200px;
    position: relative;
    text-align: center;
    .deleted-file__txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }
}


.pp_card-box {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}

.pp_card-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .2;
}

.question-mark {
    font-family: $font-family-header;
    font-size: 20px;
    float: right;
    cursor: pointer;
}

.question-mark:hover {
    color: $primary;
    cursor: pointer;
}

.card-container {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.colpick {
    margin-top: 20px;
}

// .colpick .colpick_hex_field {
//     width: 100px !important;
// }
//
.video-js .vjs-control.vjs-close-button .vjs-icon-placeholder:before, .vjs-icon-cancel:before {
    background-color: $black;
}

.vjs-brand-container {
    width: 4em;
    position: relative;
    .vjs-brand-container-link img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 14px;
    }
}

.vjs-icon-share:before {
    font-size: 14px;
}

.share-resize {
    .vjs-share__title {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }
    .vjs-share__short-link-wrapper {
        height: 25px !important;
        margin-bottom: 0px !important;
    }

    .vjs-share__subtitle {
        font-size: 13px !important;
        margin-bottom: 0px !important;
    }

    .vjs-share__btn {
        height: 25px;
    }

    .vjs-share__social {
        height: 30px;
        width: 30px;
    }

}
#offers-table_filter {
    position: absolute;
    top: -1000px;
    opacity: 0;
}

#offers-filter,
.offer-type-outer {
    float: right;
    display: inline-block;
}

#CoachTable,
#PlayerTable {
    width: 100% !important;
}

#CoachTable {
    .badge {
        margin-right: 10px;
    }
    .coach-name {
        padding-left: 37px;
    }
}

.org-lg-btn {
    margin-top: 3px;
}
// Media Queries

@media(max-width: 1275px) and (min-width: 1200px) {
    #organization-user-edit .notable-players {
        flex: 0 0 100%;
        max-width: 100%;
    }
    #organization-user-edit .coaching-philosophy,
    #organization-user-edit .hobbies-interests {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media(max-width: 768px) {
    .hype-video__wrap {
        margin-top: 25px;
    }
    #organizations-table_wrapper,
    #organizations-table_wrapper label,
    #organizations-table_wrapper #organizations-table_paginate,
    #organizations-table_wrapper #organizations-table_info,
    #users-table_wrapper,
    #users-table_wrapper label,
    #users-table_wrapper #users-table_paginate,
    #users-table_wrapper #users-table_info {
        width: 100%;
    }
    #organizations-table_wrapper input,
    #users-table_wrapper input,{
        width: 100%;
        display: block;
        margin-left: 0px;
        margin-top: 10px;
    }
    #organizations-table_wrapper #organizations-table_info,
    #users-table_wrapper #users-table_info {
        padding-bottom: 17px;
    }
    #organizations-table_wrapper ul.pagination,
    #users-table_wrapper ul.pagination{
        justify-content: center;
    }
    #users-table_wrapper .bottom {
        text-align: center;
    }
}
@media(max-width: 588px) {
    .delete-org {
        margin-top: 4px;
    }
}

@media(max-width: 576px) {
    .profile-card__title {
        text-align: center;
    }

    .profile-card__info {
        text-align: center;
    }

    .position-styles {
        text-align: center;
    }

    .position-styles__title {
        text-align: center;
    }

    .offense-style__data {
        border-right: 0px !important;
        .offense-style__data-inner {
            border-bottom: solid 1px #ccc;
        }
    }
}

@media(max-width: 575px) {
    #offers-filter {
        width: 100%;
    }
    #offers-filter #offers-search {
        width: calc(100% - 83px);
        height: 46px;
        margin-top: -3px;
    }
    #offers-filter {
         display: flex;
    }
    #offers-filter .offer-type-outer {
        display: inline-flex;
    }
}

@media(max-width: 540px) {
    #org-users .btn {
        position: absolute;
        width: 100px;
        top: -45px;
        right: 9px;
    }
}

@media(min-width: 491px) {
    #coachingstaff .manage-btn {
        max-width: 120px;
        position: absolute;
        bottom: 0;
        left: 25px;
    }
}

@media(max-width: 490px) {
    .dataTables_paginate,
    .dataTables_paginate {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center
    }
    #coachingstaff .manage-btn-outer {
        text-align: center !important;
    }
    #coachingstaff .manage-btn {
        min-width: 120px;
    }
}

@media(max-width: 360px) {
    #org-users .btn {
        position: absolute;
        width: 100px;
        top: 45px;
        right: 9px;
    }
}
