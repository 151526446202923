#events {
    span.game-dot {
        width: 16px;
        height: 16px;
        border: 2px solid #000;
        background: #000;
        border-radius: 50%;
        margin-right: 1em;
    }
    span.practice-dot {
        width: 16px;
        height: 16px;
        border: 2px solid #B0D0CE;
        background: #B0D0CE;
        border-radius: 50%;
        margin-right: 1em;
    }
    th.fc-col-header-cell {
        color: #000
    }
    .fc-daygrid-dot-event {
        .fc-daygrid-event-dot {
            &.game-dot {
                border-color: #000 !important;
                background: #000 !important;
            }
            &.practice-dot {
                border-color: #B0D0CE !important;
                background: #B0D0CE !important;
            }
        }
    }
}
