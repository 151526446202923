.player-stat-tiles-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    position: relative;
    margin: 1rem 0;

    @media (min-width: 768px) {
        justify-content: flex-start;
    }
}
