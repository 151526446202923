.lSPager {
    display: none;
}

.pad-left-right-15 {
    padding-right: 15px;
    padding-left: 15px;
}


.weight-room-title {
    margin-bottom: 20px;
}

.cream-card {
    height: 100%;
    background-color: #F8F8F8;
    box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 20px 10px;
}

.left-dropdown {
    float: left;
}

.right-dropdown {
    display: flex;
    justify-content: flex-end;
}

.dropdown-trans {
    position: relative;
    border: solid 1px #ccc;
    padding: 10px;
    font-family: KairosSans;
    text-transform: uppercase;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    width: 120px;
}

.max-lift-video-container {
    width: 80%;
}

.dropdown-trans i {
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    top: 25%;
    right: 7px;
}

.vjs-text-track-display {
    right: -2px !important;
    left: -2px !important;
}

.dropdown-trans .input-val {
    overflow-x: auto;
    white-space: nowrap;
    margin-right:15px;
    cursor: pointer;
}

.dropdown-trans i.rotate {
    transform: rotate(180deg);
}

.rb-dropdown {
    margin-bottom: 20px;
}

.view-more-max-lifts {
    position: relative;
}

.view-more-max-lifts.right {
    width: 20%;
}

.view-more-max-lifts.left {
    width: 10%;
}

.right-dropdown .dropdown-trans:nth-child(1) {
    margin-right: 10px;
}

.record-board-ol ol li {
    padding: 10px 0;
    font-family: KairosSans;
    text-transform: uppercase;
    color: #000;
}

.record-board-ol ol {
    padding-left: 15px;
}

.dropdown-filter {
    background-color: #fff;
    box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
    margin-top: 20px;
    transform: translateX(-45px);
}

.dropdown-filter ul {
    padding-left: 0px;
    list-style-type: none;
    width: 200px;
}

.dropdown-filter ul li {
    padding: 10px 20px;
    font-family: KairosSans;
    text-transform: uppercase;
    font-size: 14px;
}

.dropdown-filter ul li:hover {
    background-color: $primary;
    cursor: pointer;
}


#maxLiftSlider li {
    position: relative;
}

.tiny-circle__img {
    display: inline-block;
    position: relative;
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    background-size: cover !important;
    background-position: center !important;
}

.tiny-circle__img img {
    width: auto;
    height: 100%;
}

.wr-video-wrap {
    position: relative;
    border: solid 4px #000;
    border-radius: 3px;
}

.max-list-athletes {
    display: flex;
    margin-top: 10px;
}

.athlete-name {
    display: flex;
    justify-content: left;
    align-items: center;
}

.athlete-name h3 {
    font-size: 14px;
    margin-bottom: 0px;
}

.max-lift-verifications__wrap {
    margin-top: 50px;
}


#max-lift-verifications {
    padding-top: 50px;
}

.more-skills__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow-box-right, .arrow-box-left {
    border: solid 1px #eee;
    padding: 20px;
    cursor: pointer;
}

.arrow-box-right i, .arrow-box-left i {
    font-size: 20px;
}

.more-videos__num {
    margin-top: 15px;
}

.more-videos__num span {
    color: #ccc;
}

.wr-video-wrap .video-js .vjs-big-play-button {
    display: block !important;
    position: absolute !important;
    top: 45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    padding: 10px;
    color: #000;
    width: 50px !important;
    background-color: $primary !important;
    border: solid 1px $primary !important;
    border-radius: 100% !important;
}

.wr-video-wrap .video-js .vjs-text-track-display {
    height: 100%;
    background-color: rgba(0,0,0,.7);
    right: -1 !important;
}

.wr-video-wrap .vjs-icon-play:before,
.wr-video-wrap .video-js .vjs-play-control .vjs-icon-placeholder:before,
.wr-video-wrap .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    margin-top: 2px !important;
}

.video-skill {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.video-skill h3 {
    font-size: 14px;
    color: #fff;
}

.wr-sec-title {
    margin-bottom: 20px;
    h3 {
        font-size: 20px;
    }
}


.team-performance-chart {
    width: 100% !important;
    max-width: 800px;
    height: auto !important;
    border-top: none !important;
}

.canvas-wrapper {
    position: relative;
    max-height: 400px;
    width: 100%;
}

.max-lift-videos {
    min-height: 200px !important;
}

.resize-video {
    object-fit: cover;
    min-height: 200px !important;
}

.wr-top-flex {
    display: flex;
}

.custom-break {
    flex: 1;
}

.max-lift-flex-contain {
    display: flex;
}

#weightRoom {

    #weightRoomVerification {
        .nav-tabs {
            border-bottom: 3px solid #E7F816;
            li {
                a,
                a.active {
                    background-color: transparent;
                    color: #000;
                    border: none !important;
                    font-family: KairosSans;
                    text-transform: uppercase;
                    font-weight: 500;
                }
                a {
                     padding: 9px 20px 2px 20px !important;
                }
                a.active {
                    padding: 9px 35px 2px 33px !important;
                }
            }
        }

        .nav-border {
            width: 100%;
            border-top: 40px solid #000;
        }

        table {
            width: 100% !important;
            margin-bottom: 0px;
            box-shadow: 0 1px 15px 0px rgba(0, 0, 0, 0.1);
            border-left: 2px solid #e0e0e0;
            border-right: 2px solid #e0e0e0;
            thead {
                tr {
                    border-top: 3px solid #e0e0e0;
                    border-bottom: 3px solid #e0e0e0;

                    th:nth-child(odd) {
                        background-color: #F0F0F0;
                        color: #000;
                        border: none;
                        text-align: center;
                        border-top: 2px solid #e0e0e0 !important;
                        border-bottom: 2px solid #e0e0e0;
                    }
                    th:nth-child(even) {
                        background-color: #fbfbfb;
                        color: #000;
                        border:none;
                        text-align: center;
                        border-top: 2px solid #e0e0e0 !important;
                        border-bottom: 2px solid #e0e0e0;
                    }
                }
            }
            tbody { 
                td:nth-child(odd) {
                    background-color: #F0F0F0;
                    color: #000;
                    border: none;
                    text-align: center;
                    border-bottom: 1px solid #e0e0e0;
                }
                td:nth-child(even) {
                    background-color: #fbfbfb;
                    color: #000;
                    border:none;
                    text-align: center;
                    border-bottom: 1px solid #e0e0e0;
                }
                td {
                    .play-btn {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        // padding: 7px 15px 7px 15px;
                        // border-radius: 100%;
                        font-size: 23px;
                    }
                }
                td.content-column {
                    .preview:hover {
                        background: rgba(0,0,0,.2);
                    }
                }
                h3 {
                    margin-top: 12px;
                }
                .preview {
                    height: 150px;
                    width: 150px;
                    position:absolute;
                    top: 0px;
                    cursor: pointer;
                    transition: .3s;
                }
                img.dynamic-img {
                    width: auto;
                    height: 150px;
                }
                .default-container {
                    width: 150px;
                    height: 150px;
                    img.default-img {
                        width: auto;
                        height: 75px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
        #recentlyViewedSlider {
            padding: 6px 70px 6px 70px;
            .slick-track {
                display: flex;
                overflow: hidden;
                min-width: 100% !important;
                justify-content: center;
            }
            .slick-slide {
                height: inherit;
                margin: 10px;
                cursor:pointer;
                position: relative;
                img {
                    width: 100%; 
                }
                video {
                    width: 100%;
                    height: 100%;
                    background: #000;
                }
                .play-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    // padding: 7px 15px 7px 15px;
                    // border-radius: 100%;
                    font-size: 23px;
                }
                .default-container {
                    min-height: 125px;
                    width: 100%;
                    height: 100%;
                    img.default-img {
                        width: auto;
                        height: 75px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .slide-inner {
                    height:100%;
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    height: 100%;
                    .banner {
                        transition:.4s;
                        position: absolute;
                        bottom: -100px;
                        width: 100%;
                        opacity: .8;
                        overflow: hidden;
                        height: 60px;
                        h4 {
                            margin-bottom: 0px;
                            padding-top: 6px;
                            z-index: 30;
                            position: absolute;
                            bottom: 50%;
                            left: 50%;
                            transform: translate(-50%, 50%);
                            width: 90%;
                        }
                        .banner-color {
                            width: 100%;
                            height: 60px;
                            position: absolute;
                            bottom: 0px;
                            z-index: 1;
                        }
                    }
                }
                .slide-inner:hover .banner {
                    bottom: 0px;
                }
            }
            .arrow-left {
                font-size: 35px;
                position: absolute;
                top: calc(50% - 16px );
                transform: rotate(90deg) translateY(-50%);
                left: 0px;
                cursor: pointer;
            }
            .arrow-right {
                font-size: 35px;
                position: absolute;
                top: calc(50% - 16px );
                transform: rotate(-90deg) translateY(-50%);
                right: 0px;
                cursor: pointer;
            }
            button.slick-prev,
            button.slick-next {
                display: none;
            }
        }
        .header-text {
            margin-left: 40px;
            margin-top: 8px;
        }
    }
    .pagination {
        li {
            a {
                border: none;
                background: transparent;
                color: #000 !important;
                font-weight: 600;
                padding: 8px 6px 1px 6px;
                font-family: KairosSans;
                text-transform: uppercase;

            }
        }
        li.previous,
        li.next {
            font-weight: bolder !important;
        }
        li.active {
            a {
                border-bottom: 2px solid #000;
            }
        }

    }
    .add-measurement {
        position: absolute;
        top: -89px;
        right: 10px;
    }
}

#verificationDetail {
    transition: .3s;
    .verification-card {
        background: #fff; 
        padding: 36px;
    }
    .fa.fa-check {
        font-size: 53px;
        color: $green;
    }
    .fa.fa-close {
        font-size: 53px;
        color: #ccc;
        margin-left: 15px;
    }
    .measurement-value {
        font-size: 42px;
    }
    .photoBox {
        .video-js {
            display: none;
        }
    }
    .verification-outer {
        z-index: 1000000;
    }
    .player-verifications {
        margin-top: 11px;

        h3 {
            margin-bottom: 0px;
            margin-top: 4px;
        }
        .loading {
            margin-top: 0px;
        }
        .lds-ring {
            position: absolute;
        }
        .slick-track {
            display: flex;
            .slick-slide {
                height: inherit;
                video {
                    width: 100%;
                    height: 100%;
                    background: #000;
                }
                .play-btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    // padding: 7px 15px 7px 15px;
                    // border-radius: 100%;
                    font-size: 23px;
                }
                .default-container {
                    min-height: 65px;
                    width: 100%;
                    height: 100%;
                    img.default-img {
                        width: auto !important;
                        height: 45px !important;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
    .verification-title {
        width: 165px;
        float:left;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .player-verifications.no-content {
        .verification-title {
            width: 100%;
            position: relative;
            top: auto;
            transform: none;
            float: none;
            margin-bottom: 27px;
        }
        .verifications-wrapper {
            width: 100%;
        }
    }
    .loader-container {
        min-height: 51px;
        .loading {
            margin-top: -19px;
            margin-left: -40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
        }
    }
    .video-container{
        min-height: 367px;
        z-index: 5;
        background: #000;
    }
    .info-container {
        min-height: 367px;
        z-index: 5;
    }
    .full-screen-switch {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
    .reason-box {
        transition: .4s;
        margin-top: -200px;
        opacity: 0;
        height: 200px;
        z-index: 4;
        button {
            margin-left: 10px;
            margin-top: 10px;
        }
    }
    .reason-box.active {
        opacity: 1;
        margin-top: 10px;
    }
    .media-switchers {
        position: absolute;
        top: 5px;
        right: 5px;
        button {
            margin-left: 5px;
        }
        button[disabled] {
            curosr: pointer;
        }
    }
    #playerVerifications {
        .no-verifications {
            margin-bottom: 0px;
            padding: 40px 0px;
        }
    }
    .verifications-wrapper {
        width: calc(100% - 165px);
        float: right;
        #slider {
            padding: 0px 40px 0px 40px;
            .slick-track {
                margin-left: 0px;
            }
            .slick-slide {
                margin: 10px;
                cursor:pointer;
                position: relative;
                img {
                    width: 100%;
                    height: auto;
                }
                .slide-inner {
                     width: 100%;
                     height: 100%;
                    // background-size: cover !important;
                    // background-position: center !important;
                    // background-repeat: no-repeat !important;
                    position:absolute;
                    top: 0px;
                    cursor: pointer;
                    .banner {
                        transition:.4s;
                        position: absolute;
                        bottom: calc(-100% - 10px);
                        width: 100%;
                        opacity: .8;
                        overflow: hidden;
                        height: 100%;
                        h4 {
                            margin-bottom: 0px;
                            padding-top: 6px;
                            z-index: 30;
                            position: absolute;
                            bottom: 50%;
                            left: 50%;
                            transform: translate(-50%, 50%);
                            width: 90%;
                        }
                        .banner-color {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            bottom: 0px;
                            z-index: 1;
                        }
                    }
                }
            }
            .slick-slide:hover {
                .slide-inner {
                    .banner {
                        bottom: 0px !important;
                    }
                }
            }
            .slick-arrow {
                background: transparent !important;
            }
            .slick-arrow::before {
                color: #000;
            }
        }
        .arrow-left {
            font-size: 35px;
            position: absolute;
            top: calc(50% - 16px );
            transform: rotate(90deg) translateY(-50%);
            left: 0px;
            cursor: pointer;
        }
        .arrow-right {
            font-size: 35px;
            position: absolute;
            top: calc(50% - 16px );
            transform: rotate(-90deg) translateY(-50%);
            right: 0px;
            cursor: pointer;
        }
        button.slick-prev,
        button.slick-next {
            display: none;
        }
    }
    .options {
        position: absolute;
        bottom: 28px;
        .confirm,
        .deny {
            cursor: pointer;
        }
    }
    .videoBox,
    .measurement-video,
    .video-js {
        height: 400px;
        width: 100%;
    }
}

#measurementForm {
    .fa.fa-close {
        color: red;
    }
    .fa.fa-check {
        color: green;
    }
}

#verificationDetail.faded {
    opacity: 0;
}

#health {
    .bar-items {
        position: absolute;
        right: 42px;
        top: -52px;
        display: flex;
        .time-period {
            margin-right: 20px;
            margin-top: -3px;
            .selection {
                .select2-selection {
                    height: 30px;
                    .select2-selection__rendered {
                        line-height: 33px;
                        min-width: 220px;
                    }
                    .select2-selection__arrow {
                        height: 31px;
                    }
                }
            }
        }
        h3 {
            cursor: pointer;
            margin-top: 3px;
            font-size: 20px;
            color: #fff;
            i {
                margin-right: 10px;
            }
        }
    }
    .canvas-box {
        .chart-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -55%);
        }
        canvas#chart {
            padding: 5px 5px 0px 20px;
            max-width: 950px;
            max-height: 950px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .playerbox {
        height: calc(100% - 40px);
        min-height: 164px;
        margin-top: 20px;
        background-color: #000;
        h2 {
            text-align: center;
            margin-top: 15px;
            margin-bottom: 15px;
            color: #fff;
            margin-left:65px;
        }
        .attributes-container {
            margin-left: 65px;
            h3 {
                color: #fff;
            }
        }
        img {
            max-height: 65px;
            width: 65px;
            position: absolute; 
            top: -16px;
        }
    }
    .playerbox:not(.add-player) {
        .attributes-container {
            h2 {
                margin-bottom: 0px;
                margin-top: 0px;
                padding-bottom: 15px;
                padding-top: 15px;
            }
            h3 {
                margin-top: 0px;
                margin-bottom: -5px;
            }
        }
    }
    .playerbox.add-player {
        height: calc(100% - 40px);
        min-height: 164px;
        cursor: pointer;
        .text-container {
            height: 100%;
            padding-bottom: 20px;
            h2 { 
                margin: 0px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
            }
        }
    }

    .playerbox-outer:first-child {
        .playerbox {
            margin-left: 0px;
        }
    }
    .playerbox-outer:last-child {
        .playerbox {
            margin-right: 0px;
        }
    }
    .mr-10 {
        margin-right: 10px;
    }
    .remove-player {
        color: white;
        position: absolute;
        top: -39px;
        font-size: 24px;
        right: -14px;
        cursor: pointer;
    }
}

@media(max-width: 1450px) and (min-width: 992px) {
    #health .playerbox:not(.add-player) img {
        width: 60%;
        display: block;
        max-height: 1000px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 31px;
    }
    #health .playerbox:not(.add-player) h2,
    #health .playerbox:not(.add-player) .attributes-container {
        margin-left: 0px;
        margin-top: 25px;
        margin-bottom: 9px;
    }
    #health .playerbox:not(.add-player) .remove-player {
        top: -55px;
    }
}

@media(max-width: 1199px) {
    .playerbox-outer:nth-child(2n) .playerbox {
        margin-left: 10px;
    }
    .playerbox-outer:nth-child(3n) .playerbox {
        margin-left: 0px;
        margin-top: -10px !important;
        margin-bottom: 51px;
    }
    .playerbox-outer:nth-child(4n) .playerbox {
        margin-top: -10px !important;
        margin-bottom: 51px;
    }
}

@media(min-width: 992px) {
    .playerbox {
        margin-right: 0px;
        margin-left: 10px;
    }
    .playerbox-outer:first-child .playerbox {
        margin-right: 0px !important;
    }
    .playerbox.add-player {
        margin-right: 0px !important;
    }
}

@media(max-width: 991px) {
    #health .playerbox {
        margin-top: 22px !important;
        margin-bottom: -7px !important;
        margin-left: 0px;
        margin-right: 0px;
    }
    #health .playerbox:not(.add-player) {
        margin-bottom: -14px !important;
    }
    #health .playerbox-outer:first-child .playerbox {
        margin-top: 16px !important;
    }
}

@media(max-width: 991px) and (min-width: 401px) {
    #health .playerbox {
        min-height: 140px;
    }
}

@media(max-width: 575px) {
    #health .playerbox.add-player .text-container h2 {
        padding-top: 7px;
    }
    #health .playerbox h2 {
        font-size: 25px;
    }
    #health .playerbox h3 {
        font-size: 20px;
    }
}

@media(max-width: 400px) {
    #health .playerbox:not(.add-player) {
        height: calc(100% - 10px);
    }
    #health .playerbox:not(.add-player) img {
        width: 60%;
        display: block;
        max-height: 1000px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-top: 31px;
    }
    #health .playerbox:not(.add-player) h2,
    #health .playerbox:not(.add-player) .attributes-container {
        margin-left: 0px;
        margin-top: 25px;
        margin-bottom: 19px;
    }
    #health .playerbox:not(.add-player) .remove-player {
        top: -55px;
    }
}

#modal-component.modal .close {
    top: 17px; 
    position: absolute;
    right: 23px;
    height: auto; 
    width: auto; 
    background-color: none;
    opacity: 1;
    border: none;
    text-shadow: none;
    color: #ffffff;
    padding: 0;
    font-size: 37px;
    font-weight: lighter;
}

#modal-component {
    .modal-content {
        background: transparent;
        border: none;
    }
    .modal-header {
        border-bottom: none;
    }
}

#modal-component.faded {
    opacity: 0;
}

// Media Queries
@media (max-width: 1250px) {

    .dropdown-trans {
        font-size: 12px;
    }

    .dropdown-trans i {
        font-size: 15px;
    }

    .record-board-ol ol li {
        font-size: 12px
    }

    .dropdown-filter ul li {
        font-size: 12px;
    }

    .wr-top-flex {
        height: 300px;
    }

    .dropdown-trans {
        width: 100px;
    }

    #verificationDetail .loading {
        position: relative;
    }

    #verificationDetail .verification-outer {
        padding-left: 0px;
        margin-top: 10px;
        flex: 0 0 100% !important;
        max-width: 100%;
        z-index: 1000;
    }
    
    #verificationDetail .video-container {
        flex: 0 0 100% !important;
        max-width: 100%;
    }
    
    #verificationDetail .info-container {
        flex: 0 0 100% !important;
        max-width: 100%;
        margin-top: 11px;
        min-height: 0px;
    }

    #verificationDetail .options {
        position: relative;
        bottom: auto;
    }

    #verificationDetail .verification-title {
        width: 100%;
        float: none;
        position: relative;
        top: auto;
    }

    #verificationDetail .verifications-wrapper {
        width: 100%;
    }
}

@media (max-width: 1200px) {
    .dropdown-trans .input-val {
        margin-right: 10px !important;
    }
}

@media (max-width: 1100px) {
    .custom-break {
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
        margin-top: 25px;
    }

    .dropdown-trans {
        font-size: 14px;
    }

    .dropdown-trans i {
        font-size: 14px;
    }

    .record-board-ol ol li {
        font-size: 15px
    }

    .dropdown-filter ul li {
        font-size: 14px;
    }

    .wr-top-flex {
        flex-direction: column;
        height: auto;
    }

    .max-lift-verifications__wrap {
        margin-top: 25px;
    }

    .dropdown-trans {
        width: 120px;
    }
}

@media (max-width: 768px) {
    .arrow-box-right,
    .arrow-box-left {
        padding: 12px;
    }
}

@media(max-width: 700px) {
    #weightRoom .add-measurement {
        right: auto !important;
        top: auto !important;
        position: relative !important;
        float: right;
        width: 100%;
        text-align: right;
        margin-bottom: 13px;
    }
}

@media (max-width: 576px) {
    .col-xs-6 {
        width: 50% !important;
    }

    .right-dropdown {
        width: 100%;
        padding-top: 10px;
    }

    .left-dropdown {
        width: 100%;
    }

    .dropdown-trans {
        width: 100%;
        text-align: center;
    }

    .dropdown-trans {
        font-size: 12px;
    }

    .dropdown-trans i {
        font-size: 15px;
    }

    .record-board-ol ol li {
        font-size: 12px
    }

    .dropdown-filter ul li {
        font-size: 12px;
    }

    .weight-room-title h1{
        font-size: 30px;
    }

    .dropdown-filter {
        transform: translateX(0px);
    }

    .dropdown-filter.skill {
        transform: translateX(-20px);
        width: 100%;
    }

    .dropdown-filter ul {
        width: 100%;
    }

}

@media (max-width: 499px) {
    .dropdown-trans.history {
        margin-top: 10px;
    }

    .rb-dropdown, .tph-dropdown {
        display: flex;
        flex-direction: column;
    }

    .right-dropdown {
        display: block;
    }

    .col-xs-6 {
        width: 100% !important;
    }

    .record-board-ol ol {
        margin-bottom: 0px !important;
    }

    .dropdown-filter {
        width: 100%;
        transform: translateX(-20px);
    }

    .max-lift-video-container {
        width: 60%;
    }

    .view-more-max-lifts.right, .view-more-max-lifts.left {
        width: 20% !important;
    }

    .canvas-wrapper {
        height: 250px;
    }
}

@media(max-width: 420px) {
    #health .bar-items .time-period {
        width: 90px;
    }
}






