body, html, pre {
    font-family: $font-family-sans-serif, sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-header;
    text-transform: uppercase;
    color: #000;
}

.el-table__body-wrapper::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
}


.el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.kairos-sans {
    font-family: KairosSans;
}

.dinpro {
    font-family: DINPro, sans-serif;
}

[v-cloak] {
    display: none
}

.m-0-auto {
    margin: 0 auto;
}

.d-none {
    display: none;
}

.m-t-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.plr-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.mr-8 {
    margin-right: 8px;
}
.pt-15{
    padding-top: 15px !important;
}
.pb-15{
    padding-bottom: 15px !important;
}

.top-39 {
    top: 39% !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}
.mtb-9 {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
}
.mb-15 {
    margin-bottom: 15px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.ptb-4 {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.pb-5 {
    padding-bottom: 5px !important;
}
.ptb-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.pt-15 {
    padding-top: 15px !important;
}
.pt-10 {
    padding-top: 10px !important;
}
.pl-40 {
    padding-left: 40px !important;
}
.pl-2 {
    padding-left: 2px !important;
}

.ptb-20 {
    padding: 20px 0px !important;
}
.plr-25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.pb-10 {
    padding-bottom: 10px !important;
}
.pr-11 {
    padding-right: 11px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mt-14 {
    margin-top: 14px !important;
}


.btn-primary.disabled, .btn-primary:disabled {
    background-color: $primary;
    color: #000;
    border: none;
}

.btn-gray {
    background-color: #eee;
    border-color: #eee;
    color: #777;
}

.page-item.active .page-link {
    outline: 0 !important;
    box-shadow: none;
}

.background-image {
    background-size: cover;
    background-position: center;
}

.page-link:hover {
    color: #636e7b;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    box-shadow: none;
}

#loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    z-index: 10000;
    text-align: center;
    display: none;

    span {
        font-family: KairosSans;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 40px;
        position: relative;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.loading {
    margin-top: 30px;
    text-align: center;

    span {
        color: #fff;
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #555;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #555 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.live-activity {
    position: relative;
    left:0%;
    animation-name: showActivity;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@keyframes showActivity {
    from {
        left: 150%;
        opacity: 0;
    }
    to {
        left: 0%;
        opacity: 1;
    }
}


.live-activity {
    .text-info {
        color: $primary !important
    }
}

.right-bar {
    overflow-style: none;
}

.activity-alert {
    position: fixed;
    top:100%;
    transform: translateY(-150%) translateX(-50%);
    background-color:$primary;
    border-radius: 100%;
    cursor: pointer;
    color: rgb(255,255,255);
    padding: 15px;
    left: calc(100% - 120px)
}
.scroller {
    height: 100%
}

#breadcrumbs {
    padding-bottom: 1rem;
}

/* Alerts */
li#alerts .notify-details {
    margin-left:0px;
}

li#alerts .dropdown-menu-lg {
    max-width: 400px;
    width:400px;
    transform: translate3d(-330px, 73px, 0px) !important;
}

li#alerts p.notify-details {
    word-wrap: break-word;
}
.is-mobile {
    display: none;
}
.is-not-mobile {
    display: none;
}
.form-control {
    height: 45px;
}

/**
 * Removing Top Bar
 */
// .topbar {
//     right: auto;
// }
// .content {
//     margin-top: 0 !important;
// }
// .side-bar.right-bar {
//     top: 0;
// }

/**
 * Inline Menu List
 */
.inline-list {
    display: flex;
    margin-bottom: 20px !important;

    .dropdown-menu-lg {
        max-width: initial;
    }

    .dropdown-item {
        padding: 10px !important;
    }

    .nav-user {
         img {
            width: 24px;
            height: 24px;
         }
    }

    span {
        flex: 1;
        padding-top: 3px;
    }

    li {
        flex: 1;
        text-align: center;

        a {
            padding: 0 !important;
            border: none !important;

            i {
                margin: 0 !important;
                color :#ccc;
                font-size: 18px !important;
            }
        }
    }
}
#wrapper.enlarged .left.side-menu .inline-list {
    display:block;

    li {
        flex: none;

         a:not(.notify-all) {
            width: auto !important;
            min-height: auto !important;
            padding: 10px !important;
         }
    }
}
#wrapper.enlarged .left.side-menu #sidebar-menu > ul > li > a i {
    margin-right: 0 !important;
}

.film-room-notifications {
    .row {
        margin-bottom: -4px;
    }
    .dropdown-item.noti-title {
        padding: 10px 17px !important;
    }
    .dropdown-menu .notify-item {
        padding: 10px 35px 10px 7px !important;
        .removeBtn {
            position: absolute;
            right: 10px;
        }
    }
    a.notify-all {
        background-color: #eeeeee;
    }
    a.notify-all.view-all-dropdown-button {
        padding-left: 17px !important;
    }
    a.mark-all-dropdown-btn {
        padding-right: 10px !important;
    }
}

.label-small {
    font-size: 12px;
}

/**
 * Global Page Navigation
 *
 */
.page-nav {
    list-style: none;
    display: inline-block;
    margin: 0;

    li {
        display: inline-block;

        a {
            display: inline-block;
            padding: 10px;
            color: #888;

            &:hover {
                color: #555;
                i {
                    color: $primary;
                }
            }

            i {
                margin-left: 5px;
            }
        }
    }
}

.org-text {
    color: black !important;
}

.in-roster {
    background-color: $primary-50 !important;
    color: #000 !important;
}

.org-highlight {
    background-color: #e7f816;
}

@media(max-width: 314px) {
    .paginate_button.page-item.disabled {
        display: none;
    }
}

// Vue Formulate
// --------------------------------------------------------------------------------------------------------------------------------
.formulate-input-label {
    font-family: KairosSans, sans-serif;
    font-size: 14px;
    font-weight: 700;
}
.formulate-input-errors {
    color: red;
    list-style: none;
    padding-left: 16px;
}

// Toast Notification
// --------------------------------------------------------------------------------------------------------------------------------
#toast-container > div {
    opacity: 1;
}

.Vue-Toastification {

    &__toast {
        background-color: #262626 !important;
        background-position-y: 19px !important;
        border-style: solid;
        border-width: 1px 1px 1px 4px;
        border-radius: 0 !important;
        box-shadow: none !important;
        color: #F4F4F4;
        max-width: 600px;
        padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;
        width: calc(100vw - 100px) !important;
    }

    &__close-button {
        color: #f4f4f4 !important;
        cursor: pointer !important;
        position: absolute !important;
        background: transparent !important;
        border: none !important;
        font-size: 1.5rem !important;
        height: 12px;
        line-height: 1 !important;
        opacity: 1 !important;
        padding: 0 !important;
        right: 1rem !important;
        top: 1rem !important;
        width: 12px;
    }

    &__heading {
        display: block;
        font-family: KairosSans;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: capitalize;
        text-transform: capitalize;
    }

    &__message {
        font-size: 0.875rem;
        font-weight: 400;
        font-family: DINPro;
    }

    &__button {
        background-color: transparent;
        border: none;
        bottom: 1.15rem;
        color: #f4f4f4;
        font-family: 'DINPro';
        position: absolute;
        right: 1rem;
    }

    &__toast--success {
        border-color: #E7F816;

        .Vue-Toastification__icon {
            background-image: url('/build/images/icon_yellow_check.svg');
        }

    }

    &__toast--error {
        border-color: #D92D20;

        .Vue-Toastification__icon {
            background-image: url('/build/images/icon_red_error.svg');
        }
    }

    &__icon {
        margin: 0 1rem 0 0 !important;
        height: 20px !important;
    }
}
