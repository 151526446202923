.modal-background {
    background: transparent;
    height: 100vh !important;
    left: 0;
    position: fixed;
    transition: 250ms;
    top: 0;
    width: 100vw !important;
    z-index: -5;

    &.modal-background--open {
        background: rgba(0, 0, 0, 0.9) no-repeat center;
        background-blend-mode: darken;
        background-size: cover;
        transition: 250ms;
        z-index: 9999;
    }
}

.video-player-container {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 75%;
    justify-content: center;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: fit-content;
    z-index: 9999;

    video {
        height: 100% !important;
        padding: 0 !important;
        width: fit-content !important;

        @media (max-width: 550px) {
            height: fit-content !important;
            padding: 0 1rem !important;
            width: 100% !important;
        }

        @media (max-width: 375px) {
            height: 100% !important;
            padding: 0 1rem !important;
            width: 100% !important;
        }
    }

    .modal-title {
        font-size: 20px;
        position: absolute;
        top: -5%;
        color: #fff;

        @media (max-width: 550px) {
            font-size: 14px;
            padding: 1rem;
            top: -10%;
        }
    }

    .modal-close {
        background: transparent;
        border: 0;
        color: #fff;
        font-size: 2rem;
        line-height: 1;
        position: fixed;
        right: 1.5rem;
        top: 1.5rem;
        z-index: 100;
    }

    .has-title {
        margin-top: 3rem !important;
    }
}
