/**
  * notes/comments
  *
  * I need to spend some time cleaning this up
  * Ryan spent some time cleaning this up...
  */

#notes {
    padding: 0 20px 15px;
}

#notes {
    margin-bottom: 20px;

    textarea {
        margin-bottom:10px
    }

    textarea:focus {
        background-color: #FFF;
    }

    button {
        float: right;
    }

    .new-note, .new-comment {
        margin-bottom: 60px;
    }

    .comment {
        margin: 35px 0 35px 50px;
        position: relative;

        div.actions * {
            padding-right: 15px;
        }

        strong {
            color: #000;
            font-weight: 500;
        }

        pre {
            font-size: 14px;
        }

        .body {
            padding: 5px 0 10px;
            color: #333;
        }

        .btn-group {
            button {
                padding:5px;
            }
        }

        &::before {
            content: "";
            background-color: #eee;
            top: 40px;
            bottom: 0;
            left: -32px;
            height: 100%;
            width: 2px;
            position: absolute;
        }

        &:last-child {
            margin-bottom: 0px;

            &::before {
                background-color: white;
                height: 0;
            }
        }
    }

    .new-comment {
        textarea {
            border: 1px solid #dedede;
            background: inherit;
        }
    }


    .comment-photo {
        object-fit: cover;
        height: 3rem;
        width: 3rem;
        margin-left: -55px;
        position: absolute;
        border-radius: 50%;
    }

    .actions a {
        color: #999;
    }

    #note-filter {
        margin-bottom: 20px;
    }

    #note-filter {
        .btn-group {
            button {
                padding: 15px 25px;
                border: none !important;
                text-transform: uppercase;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}
