.message-app {
    display: flex;
    flex-direction: row;
    height: 75vh;
    width: 100%;
    height: calc(100vh - 70px);
    user-select: none;
    .closer {
        width: 5px;
        cursor: ew-resize;
        background-color: rgb(204,204,204);
    }
    .closer:hover {
        width: 10px;
    }
    .chat-sidebar.closed {
        width: 0px;
    }
    .chat-sidebar {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: 350px;
        background-color: #1f1f1f;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        position: relative;
        .chat-sidebar-search {
            width: 100%;
            flex: 0 0 45px;
            background-color: $black;
            padding: 15px;
            p {
                height: 45px;
                line-height: 45px;
                margin: 0;
                text-align: center;
                text-transform: uppercase;
                color: rgb(240,240,240);
            }
            input {
                height: 45px;
                padding: 5px;
                border: 0;
                background-color: #1f1f1f;
                color: rgb(240,240,240);
                width: 100%;
                border: 1px solid rgba(255, 255, 255, 0.1);
            }
        }
        .toggle-sidebar {
            position: absolute;
            top: 10px;
            left: 10px;
            i {
                padding: 7.86px 10px;
                border-radius: 500px;
                cursor: pointer;
            }
        }
        .toggle-sidebar.closed {
            position: fixed;
            top: 75px;
            right: 1px;
            left: auto;
        }
        .chat-sidebar-groups {
            flex: auto;
            overflow-y: scroll;
            .chat-sidebar-groups-group.active {
                background-color: $black;
                border-left: 5px $primary solid
            }
            .chat-sidebar-groups-group {
                display: flex;
                flex-direction: row;
                padding: 10px;
                position: relative;
                cursor:pointer;
                .start-a-convo {
                    margin-bottom: 15px;
                    margin-top: 15px;
                    color: rgb(235,235,235);
                    margin-left: 25px;
                    i {
                        padding: 7.86px 10px;
                        border-radius: 500px;
                        background-color: $primary;
                        color: $black;
                        margin-right: 10px;
                    }
                }
                .chat-sidebar-groups-group-info {
                    margin-left: 1rem;
                    position: relative;
                    flex-direction: column;
                    flex: 1 1 auto;
                    p {
                        padding: 0;
                        margin:0;
                        color: rgb(200,200,200) !important;
                    }
                }
                img {
                    height: 65px;
                    width: 65px;
                    border-radius: 300px;
                }
                h3 {
                    margin:0;
                    color: rgb(235,235,235);
                    align-self:center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-family: sans-serif;
                    text-transform: none;
                    font-size: 110%;
                    font-family: KairosSans;
                    text-transform: uppercase;
                }
                h3.v-centered {
                    transform: translateY(-50%);
                    top: 50%;
                    position: absolute;
                }
            }
            .chat-sidebar-unread-notifier {
                position: absolute;
                top: 7px;
                margin-left: 50px;
            }
        }
    }
    .chat-action-button {
        -webkit-appearance: none !important;
        border-radius: 35px;
        width: 20px;
        position: relative;
        top: 12px;
        left: -12px;
        font-weight: 900;

        .fa-ellipsis-h {
            padding: 2px 6px;
            border-radius: 35px;
        }
    }
    .chat-action-button.non-image {
        top: 14px !important;
        left: -26px !important;
    }
    .chat-action-button:hover {
        cursor: pointer;
    }
    .chat-screen {
        flex: auto;
        background-color: rgb(255,255,255);
        display: flex;
        flex-direction: column;
        width: 1px;
        .chat-screen-messages-loading {
            align-self: center;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1 {
                text-align: center;
            }
        }
        .chat-screen-header {
            flex: 0 0 120px;
            height: 120px;
            padding: 15px;
            margin-bottom: -10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 2px rgb(235,235,235) solid;
            h2 {
                margin: 0;
                height: 55px;
                line-height: 55px;
                font-size: 200%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            h5 {
                margin-bottom: 0px
            }
            .chat-screen-header-button {
                margin-right: 15px;
                border-radius: 1000px;
                background-color: rgb(231, 248, 21);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 45px;
                margin-left: 15px;
                cursor: pointer;
            }
        }
        .chat-screen-messages {
            flex: 1 1 auto;
            display: flex;
            margin-top: 10px;
            flex-direction: column;
            padding: 10px;
            padding-top: 20px;
            overflow: scroll;
            flex-direction: column-reverse;
            .chat-screen-messages-spacer {
                width: 100%;
                display: block;
                position: relative;
                flex: 0 0 10px;
            }
            .chat-screen-messages-load-more {
                padding: 15px;
                display: flex;
                flex-direction: column;
                button {
                    align-self: center;
                    border: 0;
                    background-color: rgb(190,190,190);
                    border-radius: 300px;
                    padding: 5px 15px;
                    color: rgba(255,255,255, .9);
                }
            }
            .date-time {
                text-align: center;
                color: rgba(0,0,0,.3);
                margin: 15px 0;
                font-family: sans-serif;
                font-weight: 400;
            }
            .chat-screen-messages-message {
                display: flex;
                flex: 0 0 auto;
                flex-direction: row;
                margin: 2.5px 0px;

                .user-avatar {
                    position: relative;
                    top: 15px;
                    margin: 0px 10px;
                    user-select: none;
                }
                .chat-screen-messages-message-content {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    .chat-screen-messages-message-content-bubble {
                        padding: 10px;
                        background-color: rgb(240,240,240);
                        border-radius: 15px;
                        white-space: pre-line;
                        word-wrap: break-word;
                        .msg-hyperlink {
                            word-wrap: break-word;
                            &:hover {
                                color: rgba(0,0,0, .6)
                            }
                            color: rgb(0,0,0)
                        }
                        .endorsement-modal-btn {
                            background-color: #E7F816;
                            color: black;
                            max-width: 150px;
                            padding: 10px 20px;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                        .endorsement-modal-btn-text {
                            font-size: 11px;
                            line-height: 13px;
                            font-weight: 700;
                        }
                        .endorsement-modal {
                            background-color: black;
                            color: white;
                            .endorsement-header {
                                border: none;
                                .endorsement-header-text {
                                    color: white;
                                    line-height: 37px;
                                    font-size: 26px;
                                    font-weight: 700;
                                    font-style: italic;
                                    text-align: center;
                                    text-transform: uppercase;
                                }
                            }
                            .endorsement-body {
                                overflow: auto;
                                max-height: 700px;
                                .endorsement-subheader {
                                    color: white;
                                    font-size: 19px;
                                    line-height: 23px;
                                    font-weight: 700;
                                    text-transform: none;
                                    font-style: normal;
                                }
                                .endorsement-upload-box {
                                    display: flex;
                                    justify-content: center;
                                    width: 250px;
                                    height: 450px;
                                    background-color: #303030;
                                    :hover {
                                        cursor: pointer;
                                    }
                                    .upload-icon {
                                        margin-top: 175px;
                                        color: #5E6871;
                                        :hover {
                                            cursor: pointer;
                                        }
                                    }
                                    #file-upload {
                                        display: none;
                                    }
                                    .selected-file-name {
                                        color: #fff;
                                        text-align: center;
                                    }
                                    .file-check {
                                        color: green;
                                        align-self: center;
                                    }
                                }
                                .part-two {
                                    flex-direction: column;
                                    :hover {
                                        cursor: none;
                                    }
                                }
                                .part-three {
                                    flex-direction: column;
                                    :hover {
                                        cursor: none;
                                    }
                                    .uploading-subheader {
                                        color: #fff;
                                        text-align: center;
                                    }
                                    .upload-percent {
                                        color: #fff;
                                        text-align: center;
                                    }
                                }
                                .endorsement-video {
                                    margin-right: 100px;
                                }
                                .endorsement-upload-btn {
                                    background-color: #E7F816;
                                    color: black;
                                    padding: 5px 35px;
                                    margin-top: 25px;
                                }
                            }
                        }
                    }
                    .chat-screen-messages-message-content-media {
                        padding: 10px 0px 10px 10px;
                        img {
                            border-radius: 15px;
                            margin-bottom: 15px;
                            margin-right: 0px;
                            width: auto;
                            height: auto;
                            max-width: 200px;
                            max-height: 200px;
                        }
                        video {
                            border-radius: 15px;
                            margin-bottom: -5px;
                            width: auto;
                            height: auto;
                            max-width: 200px;
                            max-height: 200px;
                        }
                    }
                    .chat-screen-messages-message-content-username {
                        padding: 5px;
                        font-size: 85%;
                    }
                    p {
                        user-select: text;
                        margin:0;
                        span {
                            user-select: none;
                            color: rgb(200,200,200);
                            font-size: 90%;
                            transition: opacity .5s;
                        }
                    }
                }
            }
            .chat-screen-messages-message.second-message {
                padding-left: 70px;
                margin: 2.5px 0px;
            }
            .chat-screen-messages-message.flipped.second-message {
                padding-right: 70px;
            }
            .chat-screen-messages-message.flipped {
                padding-left:0px;
                flex-direction: row-reverse;
                .chat-screen-messages-message-content {
                    max-width: calc(100% - 70px);
                    .chat-screen-messages-message-content-bubble {
                        background-color: rgb(231, 248, 21);
                        color: rgba(0,0,0, .9);
                        .msg-hyperlink {
                            &:hover {
                                color: rgba(250,250,250, .6)
                            }
                            color: rgb(240,240,240)
                        }
                    }
                    p {
                        span {
                            color: rgba(255,255,255, .6);

                        }
                    }
                    p:nth-child(2) {
                        text-align: right;
                    }
                }
            }
        }
        .chat-input {
            max-height: 135px;
            flex: 0 0 67.5px;
            width: 100%;
            display: flex;
            flex-direction: row;
            border-top: 2px rgb(245,245,245) solid;
            input {
                flex: 1 1 auto;
                padding: 10px;
                border: 0;
            }
            textarea {
                flex: 1 1 auto;
                padding: 10px;
                border: 0;
                resize: none;
                max-height: 135px;
                overflow: scroll;
                bottom: 2;
                margin-top: 13px;
            }
            .send-button {
                flex: 0 0 0;
                align-self: center;
                padding: 10px;
                cursor: pointer;
                i {
                    padding: 15px 15px;
                    border-radius: 500px;
                    background-color: $primary;
                    color: $black;
                    margin-right: 10px;
                }
            }
            .misc-button {
                 flex: 0 0 0;
                 align-self: center;
                 padding: 1px;
                 cursor: pointer;
                 i {
                    padding: 9px 9px;
                    border-radius: 250px;
                    background-color: $primary;
                    color: $black;
                    margin-right: 10px;
                 }
            }
            input::placeholder {
                color: rgba(0,0,0,.2);
            }
        }
    }
    .message-ellipsis {
        border: 1px solid #000;
        background-color: #fff;
    }
}

@media(max-width: 800px) {
    .message-app .chat-sidebar {
        position: absolute;
        height: 100vh;
        right: 0px;
    }
    .message-app .chat-sidebar.closed {
        position: relative;
    }
    .message-app .closer:not(.closed) {
        right: 350px;
        position: absolute;
        display: block;
        height: 100vh;
    }
    .message-app .msg-hyperlink {
        word-wrap: break-word;
    }
}

@media(max-width: 455px) {
    .chat-input {
        display: block !important;
        position: relative;
        flex: none;
    }
    .chat-input .misc-button {
        float: left;
        margin-top: 10px;
    }
    .chat-input textarea {
        flex: none;
        width: 80%;
        float: left;
        margin-left: 15px;
        margin-top: 0px;
    }
    .chat-input .send-button {
        position: absolute;
        right: -13px;
        top: 38px;
    }
    .message-app .chat-sidebar:not(.closed) {
        width: calc(100% - 70px);
    }
}

@media(max-width: 355px) {
    .message-app .chat-sidebar:not(.closed) {
        width: 100%;
    }
}

.advanced-search {
    padding: 10px;
    margin-bottom: 10px;
}

.chat-input {
    max-height: 135px;
    flex: 0 0 67.5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 2px rgb(245,245,245) solid;
    input {
        flex: 1 1 auto;
        padding: 10px;
        border: 0;
    }
    textarea {
        flex: 1 1 auto;
        padding: 10px;
        border: 0;
        resize: none;
        max-height: 135px;
        overflow: scroll;
        bottom: 2;
        margin-top: 13px;
    }
    .send-button {
        flex: 0 0 0;
        align-self: center;
        padding: 10px;
        cursor: pointer;
        i {
            padding: 15px 15px;
            border-radius: 500px;
            background-color: $primary;
            color: $black;
            margin-right: 10px;
        }
    }
    .misc-button {
         flex: 0 0 0;
         align-self: center;
         padding: 1px;
         cursor: pointer;
         i {
            padding: 9px 9px;
            border-radius: 250px;
            background-color: $primary;
            color: $black;
            margin-right: 10px;
         }
    }
    input::placeholder {
        color: rgba(0,0,0,.2);
    }
}
