.pill-button {
    background: #000;
    border: 0;
    border-radius: 16px;
    color: #fff;
    display: inline-block;
    font-family: 'KairosSans', sans-serif;
    font-size: 0.87rem;
    font-weight: 700;
    line-height: 1.25rem;
    padding: 0.375rem 0.75rem;
    text-align: center;
    vertical-align: middle;
}
