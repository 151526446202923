/* Box-sizing reset: //w3bits.com/?p=3225 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* The Masonry Container */
.natural-masonry {
  margin: 1.5em auto;
  column-gap: 1.5em;
}

/* The Masonry Brick */
.natural-masonry-item {
  background: #fff;
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .natural-masonry {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .natural-masonry {
    column-count: 3;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .natural-masonry {
    column-count: 2;
  }
}

.video-card {
    border: 3px solid $primary;
}
.showVideoButton {
  color: #000 !important;
  background: $primary !important;
  border-color: $primary !important;
}

.update-profile, .add-film {
	  margin: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
}

.player-profile .photo-icon {
    line-height: 22px;
    left: auto;
    right: 36%;
}

#profile-form {
    .top-save {
	      margin: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
    }
    h4 {
        margin: 20px 0;
        font-size: 20px;
    }
}

.int-questions_btn, .add-footage_btn {
	  margin: 20px;
		margin-top: 5px;
		margin-bottom: 5px;
}

.modal-header {
  border-color: $primary !important;
  display: block;
}

.modal-title {
  color: #000;
  font-family: KairosSans;
  text-transform: uppercase;
  padding-top: 5px;
}

#add-footage {
  .select2{
    margin-top: 0px;
  }
}

// .stat-revisions-created {
//   float: left;
// }

// .stat-revisions-value {
//   float: right;
// }

.player-profile {
  position: relative;

  .profile-switcher {
    margin-right: 2em; 
    margin-top: -3px;
    position: absolute;
    right: -19px; 
    top: -65px
  } 
}

@media(max-width: 535px) {
  .player-profile .profile-switcher {
    left: 12px;
    top: -17px;
    margin-top: 10px;
    margin-bottom: 15px;
    position: relative;
  }
}

.lg-backdrop.in {
  opacity: .75 !important;
}

.lg-video {
  background-color: black;
}