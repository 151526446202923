.user-avatar {

    &-image {
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-default {
        border-radius: 100%;
        background-color: #F5F6f5;
        color: #B7B7B7;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5rem; // here for blade template compatibility
        height: 5rem; // here for blade template compatibility

        .initials {
            font-family: KairosSans, sans-serif;
            line-height: 1;
            font-size: 2rem; // here for blade template compatibility
            margin-top: 0.25rem;
        }
    }
}


