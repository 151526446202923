.big-board-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.big-board-spacer {
    flex: 0 0 auto;
    width: 2.5%;
}
.big-board-placeholder {
    flex: 0 0 auto;
    width: 11%;
}

span[data-view-profile] {
    display: block;
    margin-top: 5px;
    background-color: $primary;
    color: rgb(0,0,0);
    width: 40%;
    left: 50%;
    position: relative;
    font-family: KairosSans;
    padding: 5px 25px;
    transform: translateX(-50%);

}

.punt-row {
    .big-board-card.spacing-card {
        width: 8.25% !important;
    }
}

.big-board-card {
    user-select: none;
    flex: 0 0 auto;
    width: 11%;
    &.kick_off-card, &.punt-card, &.defend_field_goal-card {
     width: 8.25% !important;
    }

    padding: 10px;
    .content-area {
        display:block;
        min-height: 5px;
    }
    h3 {
        text-align: center;
        background-color: rgb(0, 0, 0);
        color: $primary;
        padding-top: 5px;
        font-size: 175%;
        line-height: 175%;
    }
    .player-card-button {
        width: 53px;
        background-color: #f0f0f0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color .5s;
        i {
            color: rgb(220,220,220);
        }
        p {
            margin: 0;
        }
    }
    .player-card-button:hover {
        background-color: rgb(210,210,210);
    }
    .player-card-body {
        width: 250%;
        // position: relative;
        text-align: center;
        background-color: rgb(240, 240, 240);
        color: rgb(0, 0, 0);
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 125%;
        transition: background-color .5s;
        flex: 1 1 auto;
        margin-left: 2px;
        margin-right: 2px;
        overflow: auto;

        span.sd-tooltip {
            display:none;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            position: absolute;
            z-index: 9999;
            transform: translate(-50%, -100%);
            border-radius: 5px;
            padding: 15px;
            width: 300px;
            height: auto;
            background-color: white;
            left: 50%;
            top: -15px;

            .tooltip-btn-group {
                padding-right: 75px;

                .notes-link {
                    position: absolute;
                    right: 15px;
                    bottom: 15px;
                    width: 70px;
                }
            }

            &:before {
               content: ' ';
               height: 0;
               padding-top: 15px;
               position: absolute;
               width: 0;
               left: 138px;
               top: 100%;
               border: 10px solid transparent;
               border-top-color: white;
            }
        }
    }
    .player-card {
        cursor: pointer;
        display: flex
    }

    .flip-list-move {
      transition: transform 0.5s;
    }
    .no-move {
      transition: transform 0s;
    }
    .more-players {
        text-align: center;
        border-top: solid 1px rgb(200,200,200);
        padding-top: 5px;
        margin-top: 10px;
        color: rgb(150,150,150);
    }
    .more-players2 {
        display: flex;
        flex-direction: row;
        width: auto;
        align-items: center;
        padding: 5px;
        margin: 0px 15%;
        background-color: rgb(0,0,0);
        border-radius: 300px;
        margin-top: 15px;
        cursor: pointer;
        .more-text {
            margin: 0;
            flex: 1 1 auto;
            text-align:center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgb(255,255,255)
        }
    }
    .add-button {
        margin: auto;
        height: 35px;
        width: 35px;
        flex: 0 0 35px;
        border-radius: 50%;
        background-color: $primary;
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(20,20,20);
        cursor: pointer;
        span {
            align-self: center;
            font-size: 30px;
            font-weight: 300;
            color: rgb(20,20,20);
        }
    }
}

.backdrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index:1000;
    transition: opacity 1s;
    padding: 10vh 0;
    overflow-y: scroll;
}

.player-bar {
    top: 100%;
    left:0;
    position: absolute;
    width: 100vw;
    height: 10vh;
    transform: translateY(-100%);
    background-color: rgb(255,255,255);
    z-index: 1001;
}

.big-board-modal {
    z-index: 10000;
    position: fixed;
    top:0;
    left:0;
    animation-name: show;
    animation-duration: .2s;
    animation-fill-mode: forwards;
    height: 100vh;
    width: 100vw;
}

.modal-container {
    width: 48vw;
    float: left;
    left: 1vw;
    position: relative;
    height: 50vh;
    top: 50%;
    transform: translateY(-50%);
    padding: 2.5vh;
    margin: 2.5vh .5vh;
    background-color: white;
    overflow-y: scroll;
}

@keyframes show {
    from {opacity: 0}
    to {opacity: 1}

}

.colorhover {
    .player-card-body:hover {
        background-color: $primary
    }
}
.tooltip-inner {
    max-width: 100% !important;
    padding: 15px;
    background-color: rgb(255,255,255);
    color: rgb(0,0,0);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.bs-tooltip-bottom .arrow::before {
    background-color: rgb(255,255,255)
}

.account-link, .account-link-notes {
    background-color: $primary;
    padding: 5px 10px;
    margin-top: 5px;
    display: block;
}

.html2canvas-container { min-width: 2400px !important; min-height: 2400px !important; }

.generate-image {
    margin-right: 5px;
    background-color: $primary;
    border-radius: 2.5px;
    outline: none;
    padding: 7.5px 15px;
}

.previous-screenshot {
    left: 50%;
    float: none;
    transform: translateX(-50%);
    top:0%;
    height: unset;
    img {
        position: relative;
        width: 100%;
        cursor: pointer;
    }
}

.year-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .year-column {
        flex: 1;
        background-color: rgb(255,255,255);
        padding: 10px;
        margin: 10px;
    }
}

.big-board-modal .close {
    background-color: #000;
    border-radius: 50%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    height: 36px;
    margin: -1rem -1rem -1rem auto;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 2.5%;
    text-shadow: none;
    top: 27.5%;
    width: 36px;
    z-index: 9999;
}
.big-board-modal .pos-close {
    background-color: #000;
    border-radius: 50%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    height: 36px;
    margin: -1rem -1rem -1rem auto;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 1.75%;
    text-shadow: none;
    top: 11%;
    width: 36px;
    z-index: 9999;
}
.big-board-modal .dropdown-menu {
    max-height: 200px;
    overflow: scroll;
}

@media (min-width: 536px) and (max-width: 1550px) {
    .big-board-card .more-players2 {
        width: 80%;
        margin: 15px auto;
    }
}

@media (max-width: 1000px) {
    .big-board-modal .backdrop {
        padding-left: 20px;
        padding-right: 20px;
    }
    .modal-container {
        margin-left: 0px;
        width: 100%;
        top: 20%
    }
    .year-column {
        min-width: 96vw !important;
    }
    #big-board-canvas .big-board-card .content-area {
        min-height: 0px;
    }
    .big-board-modal .close {
        right: 2.2%;
        top: 3.5%;
    }
    .big-board-modal .pos-close {
        right: 4%;
    }
    .big-board-modal .year-column {
        min-width: 90vw !important;
    }
}

@media (max-width: 1200px) {
    .big-board-card {
        width: 12.5%;
        &.spacing-card, &.kick_off-card, &.field_goal-card, &.punt-card, &.defend_field_goal-card {
            width: 8% !important;
        }
    }
    .big-board-spacer {
        display: none;
    }
}

@media (min-width: 769px) and (max-width: 960px) {
    .big-board-card .content-area .player-card {
        display: flex;
        flex-wrap: wrap;
        width: 100% !important;
    }
    .big-board-card .content-area .player-card-body {
        border-bottom: 1px solid #999;
        border-top: 1px solid #999;
        margin: 0px;
        overflow: auto;
    }
    .big-board-card .content-area .player-card-button {
        margin-bottom: 5px;

        i {
            padding: 3px 0px;
        }
    }
    .big-board-card .content-area .player-card-button.handle {
        margin-bottom: 0px;
    }
}

@media (max-width: 850px) {
    .big-board-modal .close {
        right: 20px;
    }
}

@media(max-width: 768px) {
    .big-board-spacer {
        width: 1%;
    }
    #big-board-canvas .empty-card {
        display: none;
    }
    #big-board-canvas .spacing-card {
        display: none;
    }
    #big-board-canvas .big-board-spacer {
        display: none;
    }
    .big-board-card {
        min-width: 100%;
    }
    #big-board-canvas .big-board-card .add-button {
        position: absolute;
        top: -7px;
        right: -8px;
        left: auto !important;
    }
    #big-board-canvas .big-board-card .add-button.more-players-add {
        top: 8px !important;
        right: 9px !important;
    }
    #big-board-canvas .big-board-row {
        margin-bottom: 0px !important;
    }
    #big-board-canvas .big-board-card {
        min-width: 49.5%;
        padding-left: 7px;
        padding-right: 7px;
    }
    #big-board-canvas .sort-and-save {
        width: 100%;
        padding: 8px;
    }

    #big-board-canvas .sort-and-save button {
        margin-right: 0px;
    }

    #big-board-canvas .sort-and-save .btn-group {
        padding: 2px;
    }

    #big-board-canvas .sort-and-save button,
    #big-board-canvas .sort-and-save .btn-group {
        width: 50%;
        float:left;
    }
}

@media(max-width: 535px) {
    #big-board-canvas .big-board-card {
        min-width: 100%;
    }
}

@media(max-width: 510px) {
    #big-board-canvas .sort-and-save button,
    #big-board-canvas .sort-and-save .btn-group {
        width: 100%;
    }
    #big-board-canvas .sort-and-save .save-current-button {
        position: absolute;
        width: auto;
        top: -5px;
        right: 17px;
    }
}

@media (max-width: 480px) {
    .big-board-modal .modal-container h3 {
        font-size: 120%;
    }
    .big-board-modal .modal-container #pos-dropdown button {
        font-size: 20px !important;
    }
}

@media (max-width: 361px) {
    .big-board-modal .modal-container h3 {
        font-size: 100%;
    }
    .big-board-modal .modal-container #pos-dropdown button {
        font-size: 15px !important;
    }
}

#big-board-canvas {
    .theme-btn {
        border-color: rgb(225,225,225);
        border-width: 1px;
        border-radius: 0;
        padding: 7.5px 30px;
        font-family: KairosSans, serif;
        color: black !important
    }
    input.el-input__inner {
        font-family: KairosSans, serif;
        color: #000 !important;
        text-transform: uppercase !important;
    }
}
.theme-font {
    font-family: KairosSans, serif;
    color: #000 !important;
    text-transform: uppercase !important;
}
