#team-feed {
	#uploadPreview {
		.video-preview {
			max-width: 500px;
			#removeVideo {
				top: 13px !important;
				right: -9px !important;
			} 
		}
	}
	#feed {
		.feed-item {
			video {
				cursor: pointer;
				max-width: 775px;
			}
			img {
				cursor: pointer;
			}
			.multi-images {
				display: flex !important;
				max-width: 775px;
				flex-wrap: wrap;
				.img-responsive-wrap {
					float: none !important;
				    flex: 0 0 50%;
				    cursor: pointer;
				}
				.img-responsive-wrap:nth-child(odd) {
					padding: 8px 8px 8px 0;
					margin: 0px !important;
				}
				.img-responsive-wrap:nth-child(even) {
					padding: 8px 0 8px 8px;
					margin: 0px !important;
				}
				.img-responsive-wrap:last-child:nth-child(odd) {
					flex: 0 0 100% !important;
				}
			}
		}
	}
	#uploadTrigger.post-box-preview__item {
		cursor: pointer;
	}
}

@media(max-width: 991px) {
	#team-feed #feed .multi-images {
		flex-direction: column;
		width: 100%;
	}
	#team-feed #feed .multi-images .img-responsive-wrap:not(:last-child) {
		width: 100% !important;
		padding: 0px 0px 8px 0px !important;
	}
	#team-feed #feed .multi-images .img-responsive-wrap:last-child {
		width: 100% !important;
		padding: 0px !important;
	}
}