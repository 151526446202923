// Mixins ------------------------------
@mixin text-type {
    color: #000;
    background-color: #f4f4f4;
    border: none;
    border-radius: 0;
    box-shadow: inset 0px -1px 0px #8D8D8D;


    &:focus {
        box-shadow: inset 0px -1px 0px #000;
    }

    &.with-icon {
        padding-left: 50px;
    }

    &.form-control-dark {
        color: #fff;
        background-color: #262626;
        box-shadow: inset 0px -1px 0px #6F6F6F;

        &:focus {
            box-shadow: inset 0px -1px 0px #E7F816;
        }
    }
}

// Styles ------------------------------
.formulate-input {
    position: relative;

    // Data Types ------------------------------
    [data-type="checkbox"] {
        background-color: transparent;
        border: none;
        color: inherit;
        line-height: 0;

        .formulate-input-wrapper {
            align-items: center;
            display: flex;
        }
        .formulate-input-label {
            color: #f4f4f4;
            cursor: pointer;
            font-family: DINPro, sans-serif;
            font-weight: 400;
            margin-left: 0.5rem;
            margin-bottom: 0;
            text-transform: capitalize;
        }
        .formulate-input-element-decorator {
            display: none;
        }
    }

    // Icons ------------------------------
    [data-type="select"] {
        &:after {
            background-repeat: no-repeat;
            background-image: url(/build/images/icon_chevron_down_white.svg);
            background-size: contain;
            content: '';
            display: block;
            height: 8px;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
        }

        select {
            appearance: none;
            background-color: #262626;
            border-color: #6F6F6F;
            border-width: 0 0 1px 0;
            border-radius: 0;
            color: #fff;

            &:focus {
                border-color: #E7F816;
            }
        }
    }

    // Icons ------------------------------
    .form-control-start-icon {
        height: 1rem;
        left: 10px;
        position: absolute;
        top: 1rem;
        width: 1rem;
    }

    // Input Label ------------------------------
    .formulate-input-label {
        color: #C6C6C6;
    }

    // Input Element ------------------------------
    .formulate-input-element {
        position: relative;
    }

    // Textarea ------------------------------
    textarea.form-control {
        @include text-type;
    }

    // Generic Inputs ------------------------------
    .form-control {

        // Text ------------------------------
        &[type="text"] {
            @include text-type;
        }

        // Checkbox ------------------------------
        [type="checkbox"] {
            appearance: none;
            background-color: transparent;
            border: 2px solid #fff;
            border-radius: 2px;
            cursor: pointer;
            height: 15px;
            width: 15px;

            &:checked {
                background-color: #fff;

                & ~ .formulate-input-element-decorator {
                    display: block;
                    position: absolute;
                    background-image: url('/build/images/icon_checkmark_black.svg');
                    background-repeat: no-repeat;
                    top: 50%;
                    left: 50%;
                    background-size: contain;
                    background-position: center;
                    width: 12px;
                    height: 12px;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
