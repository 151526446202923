/**
 * Darken and Padding
 */
.film-room {
     background-color: #1e1e1e;
    // color: #fff;

    body {
        background-color: #1e1e1e;
        // padding-bottom: 30px;
        // padding-right: 0;
    }

    .side-menu.left {
        transition: .2s;
    }

    .topbar {
        transition: .2s;
    }

    .card {
        border: none;
        background-color: #000;

        .header-title {
            color: #fff;
        }
    }

    .table > thead > tr > th {
        background-color: #1e1e1e;
    }

    .tab-content {
        padding: 0;
    }
    .nav-tabs {
        border-bottom: 4px solid $primary;
        margin-left: -20px;
        margin-right: -20px;
    }
    .nav-item {
        border: none;
        a {
            background-color: #000;
            color: #fff;
            border-radius: 0;
            border: none;
            padding: 15px 0;

            &.active {
                background-color: $primary;
                color: #000;
            }
        }
    }

    .table td {
        border-color: #555;
        color: #fff;
    }

    .form-control:not(.note-control) {
        background-color: #000;
        border: none;
        color: #aaa;
    }


    .topbar {
        right: auto;
    }

    .content {
        margin-bottom: 75px;
        margin-top: 0 !important;
    }

    .side-bar.right-bar {
        top: 0;
    }
}

.film-room body.active {
    padding-right: 350px;

    .collapse-column {
        right: 360px;
        top: 0;
    }

    .player-list {
        right: 0;


        button,#grad_year_select {
            display: inline-block;
        }
    }
}

/**
 * Film Room
 */
.film-room {
    .make-selection {
        position: relative;
        display: block;
        text-align: center;
        color: white;
    }

    /**
     * Player list
     */
    .position {
        position: fixed;
        z-index: 1000;
        background-color: #222;
        width: 380px;
        right: 0;
        top: 0;

        [data-activate] {
            width: 10px;
        }

        .btn-group {
            button {
                padding: 15px 20px;
                background-color: transparent;
                border: none;
                border-right: solid 1px #383838;
                color: #fff;
                text-transform: uppercase;
                font-size: 14px;
                cursor: pointer;
            }
            &:last-child button {
                border: none;
            }
            .dropdown-menu {
                background-color: #333;
                border-radius: 0;
                width: 345px;
                a {
                    cursor: pointer;
                    color: #aaa;
                    padding: 10px 25px;
                    display: block;

                    &:hover {
                        color: #fff;
                        background-color: #282828;
                    }
                }
            }
        }
    }

    .collapse-column {
        position: fixed;
        height: 100%;
        right: 10px;
        top: 0;
        width: 10px;
        background-color: #333;
        // cursor: ew-resize;
        cursor: pointer;

        &:hover {
            background-color: #9f9f9f;
        }
        &:active {
            background-color: #b3b3b3;
        }
    }

    .player-list {
        width: 360px;
        position: fixed;
        top: 0;
        right: -350px;
        background-color: #222;
        height: 100%;
        box-shadow: -2px 0 4px rgba(0, 0, 0, 0.05);
        padding-top: 50px;
        padding-bottom: 70px;
        overflow: auto;

        button,#grad_year_select {
            display: none;
        }

        .widget-user {
            a {
                color: #fff;
            }
            img {
                width: 72px;
                height: 72px;
            }
        }

        .unlisted {
            margin-top: 20px;
            h4 {
                color: #fff;
                padding: 10px;
            }
        }

        .player {
            display: flex;
            align-items: center;
            padding: 28px;

            .wid-u-info {
                margin-left: 1rem;
            }
        }

    }

    .player {
        display: block;
        position: relative;
        padding: 12px;
        display: none;
        background-color: #222;

        h3 {
            margin-bottom: -6px;
        }

        &:nth-child(odd) {
            background-color: #111;
        }

        i {
            cursor: pointer;
        }
        .player-actions {
            position: absolute;
            top: 0;
            left: 5px;
            font-size: 18px;
            color: $primary;

            .dropdown-menu {
                background-color: #333;
                padding: 0;

                a {
                    padding: 8px 10px;
                    cursor: pointer;
                    display: block;
                    background-color: #333;
                    color: #fff;

                    &:hover {
                        background-color: #222;
                    }
                }
            }
        }
        .photo {
            position: relative;
            .rank {
                position: absolute;
                background-color: $primary;
                color: #000;
                width: 24px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                font-size: 12px;
                font-weight: 900;
                border-radius: 100%;
                top: -2px;
                left: -2px;
            }
        }
        .info {
            font-size: 12px;
            color: #aaa;
            h5, h3 {
                text-transform: uppercase;
                color: #fff;
            }
        }
        .hide-show {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: $primary;
            font-size: 12px;
            font-weight: 100;
            width: 24px;
            height: 24px;
            line-height: 25px;
            color: #000;
            text-align: center;
            border-radius: 100%;
        }
        .fa-times {
            font-size: 16px;
            line-height: 16px;
        }
    }

    .players {
        margin: 0 auto;

        .player {
            min-height: 0;
            display: block;
            background-color: #000;

            .photo {
                .img-responsive {
                    height: 115px;
                    width: 115px;
                }
            }
            .info {
                margin-left: 0;
                font-size: 16px;
            }
        }
    }

    /**
     * Player
     */
    .overlaid {
        .player-container:nth-child(2) {
            transform: translateX(50%);
        }
        .player-container:last-child {
            transform: translateX(-50%);
            opacity: 0.5;
        }
    }
    .player-container {
        opacity: 1;
        &.active {
            opacity: 1;
        }

        a {
            color: #fff;
        }
    }

    .full-review {
        .tab-nav {
            display: none;
        }
        .attributes {
            display: block;
        }
        .tab-content {
            padding: 0;
        }
        .film, .interview {
            display: inline-block;
            width: 50%;
            margin-right: -4px;
            padding: 15px;
            background-color: #000;
        }
        .interview {
            padding-bottom: 59px;
        }
    }
    .toggle-video, .toggle-script {
        border: none;
        background-color: #000;
        width: 100%;
        color: #fff;
        padding: 12px;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        cursor: pointer;

        i {
            margin-left: 30px;
        }
    }
    .tab-pane, .common-scripts {
        .dropdown-menu {
            background-color: #333;
            border-radius: 0;
            width: 100%;
            a {
                cursor: pointer;
                color: #aaa;
                padding: 10px 25px;
                display: block;

                &:hover {
                    color: #fff;
                    background-color: #282828;
                }
            }
        }
    }
    .stats {
        .card {
            flex-direction: row;
            flex-wrap: wrap;

            div {
                background-color: #111;
                border-right: solid 1px #222;
                border-bottom: solid 1px #222;
                flex: 1 0 25%;
                text-align: center;
                padding: 50px 30px;
                color: #ccc;

                h4 {
                    color: #fff;
                }
            }
        }
    }

    /**
     * Controls
     */
    .global-controls {
        background-color: #000;
        position: fixed;
        bottom:0;
        left:0;
        right: 0;
        height: 50px;
        box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
        z-index: 100;
        display: flex;

        .btn-group {
            position: absolute;
            transform: translate(-50%, -75%);
            left: 50%;
            padding: 18px;
            z-index: 100;
        }

        .link {
            background: none;
            border:none;
            position: absolute;
            right: 50%;
            bottom: -15px;
            transform: translateX(50%);
            color: #fff;
            font-size: 50px;
            z-index: 10;
            cursor: pointer;

            &.linked {
                color: $primary;
                right: 40px;
            }
        }

        .seek {
            flex: 1;
            cursor: pointer;
            height: 50px;
            margin-bottom: 10px;
            overflow: hidden;
            position: relative;
            background-image: url("/build/images/seek-bg.png");
            background-position: 0;

            .gradient {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to right, black 10%, transparent, black 90%);
            }
        }

        .seek:nth-child(4) {
            background-image: url("/build/images/seek-bg-white.png");
        }
    }

    .noselect {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
    }

    /**
     * Video.js
     */
    .vjs-big-play-button {
        // display: none !important;
        // width: 60px !important;
        // height: 60px !important;
        // line-height: 60px !important;
        // border-radius: 100% !important;
        // border: none !important;
        // top: 50% !important;
        // left: 50% !important;
        // transform: translate(-50%, -50%);
        // background-color: $primary !important;
        // .vjs-icon-placeholder {
        //     &:before {
        //         color: #000 !important;
        //     }
        // }
    }

    .org-vid__overlay {
        background: rgba(0,0,0,.5) !important;
        bottom: 0em;
    }

    .vjs-icon-play:before,
    .video-js .org-vid__play-btn .vjs-icon-placeholder:before {
        color: black !important;
    }

    .org-vid__play-btn {
        font-size: 50px !important;
        height: 1.5em !important;
        width: 1.5em !important;
        border-radius: 100% !important;
        left: 50% !important;
        right: 50% !important;
        -webkit-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
        margin-top: 0px !important;
        margin-left: 0px !important;
    }

    .transparent {
        background: transparent;
    }

    .video-js .org-vid__play-btn {
        border: none !important;
        background-color: #fff !important;
        color: black !important;
        opacity: .8;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .vjs-big-play-button {
        display: none !important;
    }

    .vjs-loading-spinner {
        display: none !important;
    }

    .overlaid .vjs-control-bar {
        display: none !important;
    }
    .vjs-control-bar {

        .vjs-time-controls,
        .vjs-time-divider,
        .vjs-captions-button,
        .vjs-mute-control,
        .vjs-volume-control,
        .vjs-volume-panel,
        .abLoopButton {
            display: none !important;
        }

        .vjs-progress-control {
            .vjs-load-progress {
                background-color: transparent !important;

                div {
                    background-color: transparent !important;
                }
            }
            .vjs-progress-holder {
                background-color: #000 !important;
            }
            .vjs-play-progress {
                background-color: $primary !important;

                &:before {
                    color: $primary !important;
                }
            }
        }
    }

    .film-room-table tr {
        height: 1rem;
    }
    .film-room-table th {
        text-align: center;
        border: 1px solid #ccc;
        color: #ccc;
        font-size: 12px !important;
        font-weight: normal;
        width: 10%;
    }
    .film-room-table td {
        text-align: center;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #ccc;
        padding: 10px 0px;
        width: 10%;
    }
    .film-room-table td p, .film-room-table h5 {
        margin: 0px;
    }

    .film-room-info .film-room-info-title {
        margin-top: 35px;
        color: white;
    }
    .film-room-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .stat-item {
            flex: 1 0 15%;
            min-width: 100px;
            border-color: #555 !important;
            border-style: solid;
            border-width: 1px 0px 1px 1px;

            .stat-title, .stat-value {
                font-family: KairosSans;
                font-size: 14px;
                font-weight: normal;
                padding: 5px;
            }

            .stat-title {
                background-color: #333 !important;
                border: 1px solid transparent;
                color: #fff;
            }

            .stat-value {
                font-size: 16px;
                background-color: transparent;
                color: white;
            }
        }
    }

    .film-room-stats .stat-item:last-child {
        border-width: 1px;
    }

    @media(max-width: 1079px) {
        .film-room-stats {
            flex-direction: column;
            flex-wrap: no-wrap !important;
        }
        .film-room-stats > .stat-item {
            flex: 0;
            margin-bottom: 10px;
            border-width: 1px;
        }
    }

    @media(min-width: 1026px ){
        .film-room-testing-box .stat-item:nth-child(6n) {
            border-width: 1px;
        }
    }
    @media(max-width: 1025px ){
        .film-room-testing-box .stat-item:nth-child(5n) {
            border-width: 1px !important;
        }
    }

    .film-room-card {
        padding: 0;
        box-shadow: 0 1px 15px 0px rgba(0,0,0,0.1);
        border-radius: 2px;
        -moz-border-radius: 2px;
        background-clip: padding-box;
        margin-bottom: 20px;
        background-color: #000;
    }

    .mt-20 {
        margin-top: 20px !important;
    }
}

@media(max-width: 767px) {
    .film-room .topbar {
        margin-left: -75px;
    }
    .film-room .topbar.stuck {
        margin-left: 0px;
    }
    .film-room .side-menu.left.stuck {
        margin-left: 0px;
    }
}

@media(max-width: 1199px) {
    .film-room {
        .overlaid {
            .player-container:nth-child(2) {
                transform: translateX(0%);
            }
            .player-container:last-child {
                transform: translate(0%, -100%);
                opacity: 0.5;
            }
        }
    }
}
