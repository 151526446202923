#public-splash {
    background-color: #000;
    padding: 15px;
    text-align: left;
    position: relative;

    .logo {
        max-width: 30px;
        width: 100%;
    }
    .logo-full {
        display: none;
    }
    h3, h4 {
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -40%);
    }
}
.public-menu {
    display: none;
}
.info-icons-container {
    display: none;
}
.menu-dropdown {
    display: none;
}
.athlete-info-title {
    display: none;
}
.dropdownB {
    display: none;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 812px) {
    #public-splash {
        background-color: #000;
        padding: 15px;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .logo {
        max-height: 30px;
        align-self: center;
    }
    .public-menu {
        display: block;
        margin-bottom: -10px;
    }
    .footer {
        background-color: black;
    }
    .web-nav {
        display: none;
      }
    .position-div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    .position-title {
        margin-top: 10px;
    }
    .info-box {
        margin-top: 20px;
    }
    .info-icons-container {
        display: flex;
        justify-content: space-evenly;
        min-width: 300px;
    }
    .icon-box {
        max-width: 40px;
        max-height: 40px;
    }
    .info-icons {
        border-radius: 100px;
        background-color: #C6C6C6;
        padding: 10px;
    }
    .info-icons-has-data {
        border-radius: 100px;
        background-color: #EBF753;
        padding: 10px;
    }
    .info-text {
        font-size: 10px;
        margin-top: 15px;
        margin-bottom: 0px;
        text-align: center;
    }
    .player-profile {
        display: block;
    }
    .profile-card-section {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .player-name {
        padding-left: 0px;
    }
    .about-me {
        margin-top: 1rem;
        margin-bottom: 0rem;
    }
    .col-4 {
        max-width: 100%
    }
    .col-8 {
        max-width: 100%;
    }
    .nav-link {
        font-size: 16px;
        background-color: #FFFFFF;
        font-family: 'Quantico', sans-serif;
        color: black;
        border-bottom: none !important;
        padding: 15px;
    }
    .active-nav-link {
        display: block;
        background-color: black;
        color: white;
        font-size: 16px;
        font-family: "Quantico", sans-serif;
        border-bottom: none !important;
        padding: 15px;
      }
    .tabs-bordered .nav-item {
        width: 100%;
        margin-bottom: 0px;
    }
    .stats {
        flex-direction: column;
    }
    .stats .stat-item {
        flex-basis: 0%;
        display: flex;
        width: 100%;
        min-width: 300px;
        max-width: 300px;
        margin: 5px 10px;
        border: none;
        border-bottom: 0.25px solid #ccc;
    }
    .stat-key {
        flex: 2;
        margin-bottom: 5px
    }
    .stat-value {
        flex: 3;
        display: flex;
        justify-content: space-evenly;
        max-height: 30px;
    }
    .text-value {
        font-family: 'Quantico', sans-serif;
        font-size: 13px;
        margin: 0px;
    }
    .recruit-info-container {
        padding: 20px 0px;
    }
    .h4, h4 {
        padding-left: 10px;
    }
    .athlete-info-title {
        display: flex;
        justify-content: space-between;
    }
    .profile-card-athlete-info .athlete-info-data {
        opacity: 0;
        max-height: 0px;
        overflow-y: hidden;
        transition: all 0.4s ease-out;
    }
    .profile-card-athlete-info-open .athlete-info-data {
        opacity: 1;
        max-height: 550px;
        transition: all 0.4s ease-out;
    }
    .athlete-info-key {
        flex: 2;
        margin-bottom: 0px;
        padding-left: 20px;
    }
    .athlete-info-value {
        flex: 3;
        text-transform: none;
        line-height: 19.2px;
        min-width: 225px;
        margin-bottom: 0px !important;
        margin-top: 5px;
    }
    .dropdownB {
        display: block;
    }
    .dropdown-options {
        overflow: hidden;
        transition: all 0.4s ease-out;
    }
    .nav-options {
        flex-direction: column;
        border-top: none;
        background-color: #FFFFFF;
        list-style: none;
        padding: 0;
        width: 100%;
        margin-bottom: 0px;
        border: 2px solid yellow;
    }
    .menu-dropdown {
        display: flex;
        justify-content: space-between;
        border: 2px solid #EBF753
    }
    .menu-dropdown-active {
        display: flex;
        justify-content: space-between;
    }
    .selected-dropdown {
        padding: 15px;
        margin-bottom: 0px;
    }
    .dropdown-active {
        border: 2px solid #EBF753
    }
    #notes .comment-photo {
        border: 1px solid #EBF753;
    }
    .comment-length {
        text-align: center;
        border-bottom: 1px solid #E5E5E5;
        padding: 15px 0px;
    }
    #notes .comment:last-child::before {
        background-color: #EBF753;
        height: 100%;
        width: 1px;
    }
    .load-button {
        width: 258.01px;
        height: 40px;
        background: #88929D;
        border-radius: 4px;
        color: white;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .load-more {
        margin: auto;
    }
    .show-more {
        padding-top: 10px;
        text-transform: uppercase;
    }
    .login-button {
        width: 50%;
        align-self: center;
        font-size: 12px;
        background-color: #EBF753;
        border: none;
        text-align: center;
      }
      .login-text {
        margin-top: 1rem;
        text-align: center;
      }
      .logged-out-box{
        background-color: black;
        color: white;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        font-family: 'Quantico', sans-serif;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-style: normal;
        padding: 20px 0px;
      }
    .tab-pane#notes {
        padding: 0px;
    }
    .stats-bb-box {
        display: flex;
        justify-content: space-between;
        padding-right: 30px;
    }
    .stat-type-box {
        display: flex;
        justify-content: space-between;
    }
    .modal-header-lo {
        display: flex;
        justify-content: center;
    }
    .modal-body-lo {
        display: flex;
        justify-content: center;
    }
    .modal-buttons-lo {
        display: flex;
        justify-content: space-evenly;
    }
    .login-modal-btn {
        border: none;
        background-color: #E7F818;
        padding: 10px 20px;
        font-family: 'KairosSans';
        text-transform: uppercase;
        color: black;
        text-align: center;
    }
    .signup-modal-btn {
        border: none;
        background-color: #D5D5D5;
        padding: 10px 20px;
        font-family: 'KairosSans';
        text-transform: uppercase;
        color: black;
        text-align: center;
    }
    .container-modal {
        display: flex;
        flex-wrap: nowrap;
    }
    .modal-header-loc {
        background-color: black;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
}
@media only screen and (min-device-width: 410px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    #public-splash {
        background-color: #000;
        padding: 15px;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .logo {
        max-height: 30px;
        align-self: center;
    }
    .public-menu {
        display: block;
        margin-bottom: -10px;
    }
    .footer {
        background-color: black;
    }
    .web-nav {
        display: none;
      }
    .info-box {
        margin-top: 20px;
    }
    .info-icons-container {
        display: flex;
        justify-content: space-evenly;
        min-width: 300px;
    }
    .icon-box {
        max-width: 40px;
        max-height: 40px;
    }
    .info-icons {
        border-radius: 100px;
        background-color: #C6C6C6;
        padding: 10px;
    }
    .info-icons-has-data {
        border-radius: 100px;
        background-color: #EBF753;
        padding: 10px;
    }
    .info-text {
        font-size: 10px;
        margin-top: 15px;
        margin-bottom: 0px;
        text-align: center;
    }
    .player-profile {
        display: block;
    }
    .profile-card-section {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .player-name {
        padding-left: 0px;
    }
    .about-me {
        margin-top: 1rem;
        margin-bottom: 0rem;
    }
    .col-4 {
        max-width: 100%
    }
    .col-8 {
        max-width: 100%;
    }
    .nav-link {
        font-size: 16px;
        background-color: #FFFFFF;
        font-family: 'Quantico', sans-serif;
        border-bottom: none !important;
        color: black;
        padding: 15px;
    }
    .active-nav-link {
        display: block;
        background-color: black;
        color: white;
        font-size: 16px;
        font-family: "Quantico", sans-serif;
        border-bottom: none !important;
        padding: 15px;
    }
    .tabs-bordered .nav-item {
        width: 100%;
        margin-bottom: 0px;
    }
    .stats {
        flex-direction: column;
    }
    .stats .stat-item {
        flex-basis: 0%;
        display: flex;
        width: 100%;
        min-width: 300px;
        max-width: 330px;
        margin: 5px 10px;
        border: none;
        border-bottom: 0.25px solid #ccc;
    }
    .stat-key {
        flex: 2;
        margin-bottom: 5px
    }
    .stat-value {
        flex: 3;
        display: flex;
        justify-content: space-evenly;
        max-height: 30px;
    }
    .text-value {
        font-family: 'Quantico', sans-serif;
        font-size: 13px;
        margin: 0px;
    }
    .recruit-info-container {
        padding: 20px 0px;
    }
    .h4, h4 {
        padding-left: 10px;
    }
    .athlete-info-title {
        display: flex;
        justify-content: space-between;
    }
    .profile-card-athlete-info .athlete-info-data {
        opacity: 0;
        max-height: 0px;
        overflow-y: hidden;
        transition: all 0.4s ease-out;
    }
    .profile-card-athlete-info-open .athlete-info-data {
        opacity: 1;
        max-height: 550px;
        transition: all 0.4s ease-out;
    }
    .athlete-info-key {
        flex: 2;
        margin-bottom: 0px;
        padding-left: 20px;
    }
    .athlete-info-value {
        flex: 3;
        text-transform: none;
        line-height: 19.2px;
        min-width: 225px;
        margin-bottom: 0px !important;
        margin-top: 5px;
    }
    .dropdownB {
        display: block;
    }
    .dropdown-options {
        overflow: hidden;
        transition: all 0.4s ease-out;
    }
    .nav-options {
        flex-direction: column;
        border-top: none;
        background-color: #FFFFFF;
        list-style: none;
        padding: 0;
        width: 100%;
        margin-bottom: 0px;
        border: 2px solid yellow;
    }
    .menu-dropdown {
        display: flex;
        justify-content: space-between;
        border: 2px solid #EBF753
    }
    .menu-dropdown-active {
        display: flex;
        justify-content: space-between;
    }
    .selected-dropdown {
        padding: 15px;
        margin-bottom: 0px;
    }
    .dropdown-active {
        border: 2px solid #EBF753
    }
    #notes .comment-photo {
        border: 1px solid #EBF753;
    }
    .comment-length {
        text-align: center;
        border-bottom: 1px solid #E5E5E5;
        padding: 15px 0px;
    }
    #notes .comment:last-child::before {
        background-color: #EBF753;
        height: 100%;
        width: 1px;
    }
    .load-button {
        width: 258.01px;
        height: 40px;
        background: #88929D;
        border-radius: 4px;
        color: white;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .load-more {
        margin: auto;
    }
    .show-more {
        padding-top: 10px;
        text-transform: uppercase;
    }
    .login-button {
        width: 50%;
        align-self: center;
        font-size: 12px;
        background-color: #EBF753;
        border: none;
        text-align: center;
      }
    .login-text {
        margin-top: 1rem;
        text-align: center;
    }
    .logged-out-box{
        background-color: black;
        color: white;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        font-family: 'Quantico', sans-serif;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-style: normal;
        padding: 20px 0px;
    }
    .tab-pane#notes {
        padding: 0px;
    }
    .modal-header-lo {
        display: flex;
        justify-content: center;
    }
    .modal-body-lo {
        display: flex;
        justify-content: center;
    }
    .modal-buttons-lo {
        display: flex;
        justify-content: space-evenly;
    }
    .login-modal-btn {
        border: none;
        background-color: #E7F818;
        padding: 10px 20px;
        font-family: 'KairosSans';
        text-transform: uppercase;
        color: black;
        text-align: center;
    }
    .signup-modal-btn {
        border: none;
        background-color: #D5D5D5;
        padding: 10px 20px;
        font-family: 'KairosSans';
        text-transform: uppercase;
        color: black;
        text-align: center;
    }
    .container-modal {
        display: flex;
        flex-wrap: nowrap;
    }
    .modal-header-loc {
        background-color: black;
        display: flex;
        justify-content: center;
        padding: 10px;
    }

}
@media only screen and (max-device-width: 320px) {
    #public-splash {
        background-color: #000;
        padding: 15px;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .logo {
        max-height: 30px;
        align-self: center;
    }
    .public-menu {
        display: block;
        margin-bottom: -10px;
    }
    .footer {
        background-color: black;
    }
    .web-nav {
        display: none;
    }
    .info-box {
        margin-top: 20px;
    }
    .info-icons-container {
        display: flex;
        justify-content: space-evenly;
        min-width: 300px;
    }
    .icon-box {
        max-width: 40px;
        max-height: 40px;
    }
    .info-icons {
        border-radius: 100px;
        background-color: #C6C6C6;
        padding: 10px;
    }
    .info-icons-has-data {
        border-radius: 100px;
        background-color: #EBF753;
        padding: 10px;
    }
    .info-text {
        font-size: 10px;
        margin-top: 15px;
        margin-bottom: 0px;
        text-align: center;
    }
    .player-profile {
        display: block;
    }
    .profile-card-section {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .player-name {
        padding-left: 0px;
    }
    .about-me {
        margin-top: 1rem;
        margin-bottom: 0rem;
    }
    .col-4 {
        max-width: 100%
    }
    .col-8 {
        max-width: 100%;
    }
    .nav-link {
        font-size: 16px;
        background-color: #FFFFFF;
        font-family: 'Quantico', sans-serif;
        border-bottom: none !important;
        color: black;
        padding: 15px;
    }
    .active-nav-link {
        display: block;
        background-color: black;
        color: white;
        font-size: 16px;
        font-family: "Quantico", sans-serif;
        border-bottom: none !important;
        padding: 15px;
    }
    .tabs-bordered .nav-item {
        width: 100%;
        margin-bottom: 0px;
    }
    .stats {
        flex-direction: column;
    }
    .stats .stat-item {
        flex-basis: 0%;
        display: flex;
        width: 100%;
        margin: 5px 10px;
        border: none;
        border-bottom: 0.25px solid #ccc;
        max-width: 245px;
    }
    .stat-key {
        flex: 2;
        margin-bottom: 5px
    }
    .stat-value {
        flex: 3;
        display: flex;
        justify-content: space-evenly;
        max-height: 30px;
    }
    .text-value {
        font-family: 'Quantico', sans-serif;
        font-size: 13px;
        margin: 0px;
    }
    .recruit-info-container {
        padding: 20px 0px;
    }
    .h4, h4 {
        padding-left: 10px;
    }
    .athlete-info-title {
        display: flex;
        justify-content: space-between;
    }
    .profile-card-athlete-info .athlete-info-data {
        opacity: 0;
        max-height: 0px;
        overflow-y: hidden;
        transition: all 0.4s ease-out;
    }
    .profile-card-athlete-info-open .athlete-info-data {
        opacity: 1;
        max-height: 550px;
        transition: all 0.4s ease-out;
    }
    .athlete-info-key {
        flex: 2;
        margin-bottom: 0px;
        padding-left: 20px;
        max-width: 110px;
    }
    .athlete-info-value {
        flex: 3;
        text-transform: none;
        line-height: 19.2px;
        min-width: 170px;
        margin-bottom: 0px !important;
        margin-top: 5px;
    }
    .dropdownB {
        display: block;
    }
    .dropdown-options {
        overflow: hidden;
        transition: all 0.4s ease-out;
    }
    .nav-options {
        flex-direction: column;
        border-top: none;
        background-color: #FFFFFF;
        list-style: none;
        padding: 0;
        width: 100%;
        margin-bottom: 0px;
        border: 2px solid yellow;
    }
    .menu-dropdown {
        display: flex;
        justify-content: space-between;
        border: 2px solid #EBF753
    }
    .menu-dropdown-active {
        display: flex;
        justify-content: space-between;
    }
    .selected-dropdown {
        padding: 15px;
        margin-bottom: 0px;
    }
    .dropdown-active {
        border: 2px solid #EBF753
    }
    #notes .comment-photo {
        border: 1px solid #EBF753;
    }
    .comment-length {
        text-align: center;
        border-bottom: 1px solid #E5E5E5;
        padding: 15px 0px;
    }
    #notes .comment:last-child::before {
        background-color: #EBF753;
        height: 100%;
        width: 1px;
    }
    .load-button {
        width: 258.01px;
        height: 40px;
        background: #88929D;
        border-radius: 4px;
        color: white;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .load-more {
        margin: auto;
    }
    .show-more {
        padding-top: 10px;
        text-transform: uppercase;
    }
    .login-button {
        width: 50%;
        align-self: center;
        font-size: 12px;
        background-color: #EBF753;
        border: none;
        text-align: center;
      }
    .login-text {
        margin-top: 1rem;
        text-align: center;
    }
    .logged-out-box{
        background-color: black;
        color: white;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        font-family: 'Quantico', sans-serif;
        justify-content: center;
        display: flex;
        flex-direction: column;
        font-style: normal;
        padding: 20px 0px;
    }
    .tab-pane#notes {
        padding: 0px;
    }
    .modal-header-lo {
        display: flex;
        justify-content: center;
    }
    .modal-body-lo {
        display: flex;
        justify-content: center;
    }
    .modal-buttons-lo {
        display: flex;
        justify-content: space-evenly;
    }
    .login-modal-btn {
        border: none;
        background-color: #E7F818;
        padding: 10px 20px;
        font-family: 'KairosSans';
        text-transform: uppercase;
        color: black;
        text-align: center;
    }
    .signup-modal-btn {
        border: none;
        background-color: #D5D5D5;
        padding: 10px 20px;
        font-family: 'KairosSans';
        text-transform: uppercase;
        color: black;
        text-align: center;
    }
    .container-modal {
        display: flex;
        flex-wrap: nowrap;
    }
    .modal-header-loc {
        background-color: black;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
}
