.infiput {
    position: relative;

    .fields {
        position: relative;

        .remove {
            position: absolute;
            top: 26px;
            right: 14px;
        }
    }
}
