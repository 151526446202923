#calendars-table {
	width: 100% !important;
}

@media(max-width: 768px) {
	#calendars-table_wrapper,
	#calendars-table_filter,
	#calendars-table_filter label,
	#calendars-table_filter input {
		width: 100%;
	}

	#calendars-table_filter input {
		display: block;
		margin-top: 8px;
		margin-left: 0px;
	}
}