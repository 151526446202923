.f-size-12 {
    font-size: 12px !important;
}

.m-b-10 {
    margin-bottom: 10px;
}

.tabs-bordered{
    border-top: 4px solid $primary;
    background-color: black;
    border-bottom: 2px solid black !important;
}
.nav-tabs .nav-link.active {
    background-color: $primary !important;
    border-bottom: 2px solid $primary !important;
    color: black !important;
    padding: 12px 10px !important;
}
.nav-tabs .nav-link{
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid black !important;
    padding: 12px 10px !important;
    text-align: left;
    width: 100%;
}
.tabs-bordered li .nav-link{
    padding: 12px 10px !important;
}
.tabs-bordered li .nav-link:hover{
    padding: 12px 10px !important;
    border-bottom: 2px solid $primary !important;
}

.profile-card {
    box-shadow: 0 1px 15px 0px rgba(0,0,0,0.1);
    padding: 0px 0px;
    margin-bottom: 25px;
    min-height: 97%;
}
.profile-card-section {
    margin-bottom: 20px;
    border-bottom: 2px solid #ccc;
    padding: 20px 10px;
}
.profile-card-section .btn {
    border-radius: 0;
    font-weight: lighter;
    font-size: 12px;
    color: #ccc !important;
    margin-bottom: 10px;
    padding: 8px 15px;
}
.profile-card-section h5 {
    line-height: 1.98;
    margin-bottom: 11px !important;
}
.sd-subscription-icon {
    background-color: $primary;
    position: absolute;
    top: 15px;
    left: 22px;
    padding: 10px 12px;
    border-radius: 100%;
    font-weight: bold;
}

.profile-pic {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover;
}

.profile-pic-lg {
    height: 200px;
    width: 200px;
    object-fit: cover;
}


.g-icon {
    color: black;
    background-color: $primary;
    padding: 4px;
    font-size: 8px;
    border-radius: 100%;
    width: 19px;
    height: 19px;
}
.fa-check {
    font-size: 12px;
    padding-top: 3px;

    &.subscribed {
        padding-top: 10px;
    }
}

.fa-times {
    font-size: 12px;
    padding-top: 3px;
}
.fa-envelope {
    line-height: 11px;
}
.fa-comment-alt {
    line-height: 13px;
}
.player-profile .fa-address-book-o {
    line-height: 11px;
    font-size: 10px;
    .pointer {
        cursor: pointer;
    }
}
.send-contact-info:hover {
    cursor: pointer;
}
.numbered-icon {
    position: absolute;
    top: 20px;
    left: 36%;
    font-size: 14px;
    font-weight: bold;
    width: 30px;
    height: 30px !important;
}

.recruit-info-container {
    padding: 20px;
}

.text-uppercase {
    font-family: KairosSans;
    font-size: 14px;
    font-weight: normal;
    padding: 5px;
}

.slidein {
    opacity: 0;
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}
.slideout {
    opacity: 1;
}
.recruit-info {
    padding-bottom:35px
}

.recruit-info-table tr {
    height: 1rem;
}
.showVideoButton {
    border-radius: 0;
    font-weight: lighter;
    font-size: 12px;
    color: #ccc !important;
    padding: 3px 30px;
    margin-bottom: 30px;
}
.recruit-info-table th {
    text-align: center;
    border: 1px solid #ccc;
    color: #ccc;
    font-size: 12px !important;
    font-weight: normal;
    width: 10%;
}
.recruit-info-table td {
    text-align: center;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #ccc;
    padding: 10px 0px;
    width: 10%;
}
.recruit-info-table td p, .recruit-info-table h5 {
    margin: 0px;
}

.video-card {
    padding: 10px 5px;
    margin-top: 15px;
    margin-bottom: 10px;
    display: block !important;
}

.video-row {
    padding-left: 10px;
    padding-right: 10px;
}
@media(max-width: 768px) {
    .video-row {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
.video-card .title {
    margin-top: 20px;
    margin-bottom: 6px;
}
.video-card hr {
    margin-top: 0px;
    border-top: 3px solid cornflowerblue;
    width: 33px;
}
.video-card .video .video-js {
    width: 100%;
}

.video {
    display: block;
}
.videojs-implement {
    object-fit: fill !important;
    display:inline-block;
}

.stats {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;

    .stat-item {
        flex: 1 0 15%;
        min-width: 100px;
        border-color: #ccc;
        border-style: solid;
        border-width: 1px 1px 1px 1px;

        .stat-title, .stat-value {
            font-family: KairosSans;
            font-size: 14px;
            font-weight: normal;
            padding: 5px;
        }

        .stat-title {
            background-color: $black;
            color: #ccc;
        }

        .stat-value {
            font-size: 16px;
            font-family: KairosSans;
        }
    }
}

img.attribute-media {
    cursor: pointer;
}

img.attribute-media:hover {
    border: 1px solid $primary;
}

.lg-sub-html {
    text-transform: uppercase;
    font-family: KairosSans;
}

.body > button {
    margin: 5px;
}

.focus {
    animation: fadeout 15s;
    padding:5px;
}

.highlight {
    background-color: #FFFFDB;
}

.checkbox label {
    font-size: inherit;
}

table.datatable thead .sorting::before {
    position: initial;
}

table.datatable thead .sorting::after {
    position: initial;
}

.btn {
    outline: 0 !important;
    box-shadow: none !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    background-color: $primary;
    font-weight: 600;
    box-shadow: none;
    border: none;
    color: #000;
}

#roster-filter .select2-container, .form-inline .select2-container {
    width:150px !important;
}

@media(max-width: 575px) {
    #rosterSearchInfo #roster-filter .select2-container {
        width: calc(50% - 1.7px ) !important;
        padding-left: 0px;
    }
}

.initial-position {
    width: 225px;
}

#recruit-table_wrapper,
#roster-table_wrapper {
    .player-progress {
        i {
        left: 50%;
        }
    }
}

@media(max-width: 768px) {
    .initial-position {
        width: 100%;
    }
}

@media(max-width: 1200px) {
    .player-profile .film-outer,
    .player-profile .interview-outer,
    .player-profile .additional-footage-outer {
        justify-content: left !important;
    }
}

@media(max-width: 824px) and (min-width: 769px) {
    .message-player-btn {
        display: inline-block;
    }
}

@media(max-width: 600px) {
    #recruit-table_wrapper .dataTables_length{
        width: 100%;
        text-align: center;
    }
    #recruit-table_wrapper .dataTables_length {
        margin-top: 4px;
    }
    #recruit-table_wrapper .paging_simple_numbers {
        width: 100%;
    }
    #recruit-table_wrapper .pagination {
        justify-content: center;
        margin-bottom: 20px;

    }
}

@media(max-width: 510px) {
    #advanced_search_criteria .advanced-save-search-container,
    #advanced_search_criteria .advanced-search-submit-container {
        flex: 0 0 100% !important;
        max-width: 100%;
        text-align: center;
    }
    #advanced_search_criteria .advanced-search-submit-container .advanced_search_submit {
        float: none !important;
    }
    #advanced_search_criteria .select2 {
        width: 100% !important;
    }
    :not(#advanced_search_criteria) .select2-container.select2-container--open,
    :not(#advanced_search_criteria) .select2-dropdown {
        max-width: calc(100% - 40px) !important;
        width: calc(100% - 40px) !important;
    }
}

@media(max-width: 310px) {
    #advanced_search_criteria button {
        font-size: 1rem !important;
        padding: .375rem .75rem;
    }
}

@keyframes fadeout {
  from {
    background-color: #ffff99;
    // font-weight: bold;
  }
  to {
    background-color:initial;
    font-weight: normal;
  }
}

@media (max-width: 992px) {
    #roster-filter {
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 576px) {
    .save-search-btn {
        margin-bottom: 10px;
    }

    .saved-search-wrap {
        margin-bottom: 10px;
    }

    .height-bottom {
        margin-top: 10px;
    }
}

.player-profile {
    span.message-btn {
        font-size: 10px;
        padding-top: 4px;
    }
}

.see-why-btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px;
    font-size: 0.95rem;
    padding: 0.5rem 1rem;
    font-weight: 400;
    margin-bottom: 12px;
}

#profile-form {
    #positionContainer {
        .select2-selection.select2-selection--multiple{
            border: 1px solid #e1e1e1 !important;
            max-height: 46px;
            min-height: 44px;
            border-radius: 0px;
            .select2-selection__rendered {
              padding: 2px 10px;
              max-height: 44px;
              overflow-y: auto;
              min-height: 44px;
            }
        }

    }
}

.player-menu {
    list-style: none;

    li {
        padding: 5px 0;
    }
}
