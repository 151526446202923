.plan-stats {
    margin-top: 20px;
    margin-bottom: 0;
    padding-left: 15px;

    li {
        line-height: 30px;
    }
}

.product-plans {
    display: flex;
    button {
        margin: 0 0 20px;
        background-color: #555;
        color: #fff;
        border:none;
        padding: 30px 10px;
        font-size: 18px;
        cursor: pointer;
        flex: 1;
        text-transform: uppercase;
        font-family: KairosSans;

        &.active {
            background-color: #222;
        }
    }
}

.responsive {
    span {
        display:none;
        font-weight: bold;
    }
    @media (max-width: 990px) {
        thead {
            display: none;
        }
        td {
            display: block;
            text-align: right;
        }
        span {
            display:block;
            float:left;
        }
    }
}

.select2-container {
    margin-left: 0px !important;
}

@media(min-width: 769px) {
    .same-as-player {
        float: right;
        margin-top: -10px !important;
    }

}

#account {
    .select2-container {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        height: 43px;
    }
}

.reset-password {
    margin-top: 8px;
}