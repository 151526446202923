#advanced_searchBtn {
    float: right;
    font-size: 20px;
    height: 50px;
}

#stats-search {
    .el-input-number--mini.is-without-controls {
        width: 55px !important;
    }
    .el-slider {
        width: 180px !important;
        margin-left: 12px !important;
        margin-right: 12px !important;
    }
    div.el-input.el-input--mini {
        input.el-input__inner {
            padding: 0 !important;
        }
    }
    div.col-6 {
        h4 { text-align: center; }
    }
}
